import {AppEnvironment} from "../../../lib/model/config/app-environment.model";
import {Dictionary} from "../../../lib/model/dictionary.model";

export const AppEnvironments: Dictionary<AppEnvironment> = {
	demo: {
		apiUrl: "https://demo.movebe.app",
		apiUrlLocal: "http://localhost:5001/movebe-demo/us-central1/api",
		firebaseConfig: {
			apiKey: "AIzaSyCgiA0YM2obLi_iWxXFX1KyMbI89K5hATw",
			authDomain: "movebe-demo.firebaseapp.com",
			databaseURL: "https://movebe-demo.firebaseio.com",
			messagingSenderId: "1099008473959",
			projectId: "movebe-demo",
			storageBucket: "movebe-demo.appspot.com",
		},
		isProduction: false,
		logErrors: true,
	},
	joey: {
		apiUrl: "https://joey.movebe.app",
		apiUrlLocal: "http://localhost:5001/movebe-joey/us-central1/api",
		firebaseConfig: {
			apiKey: "AIzaSyChZqM1-nuL7v77fG5Q5dFzHc5r2uH_Pxc",
			authDomain: "movebe-joey.firebaseapp.com",
			databaseURL: "https://movebe-joey.firebaseio.com",
			messagingSenderId: "86794712152",
			projectId: "movebe-joey",
			storageBucket: "movebe-joey.appspot.com",
		},
		isProduction: false,
		logErrors: false,
		useMerchantSearchName: true,
	},
	master: {
		apiUrl: "https://master.movebe.app",
		apiUrlLocal: "http://localhost:5001/movebe-master/us-central1/api",
		firebaseConfig: {
			apiKey: "AIzaSyDRlIQnxf28z-A0L1KDwLKxYyPI3dcVCWw",
			authDomain: "movebe-master.firebaseapp.com",
			databaseURL: "https://movebe-master.firebaseio.com",
			messagingSenderId: "254933375892",
			projectId: "movebe-master",
			storageBucket: "movebe-master.appspot.com",
		},
		isProduction: false,
		logErrors: true,
	},
	milt: {
		apiUrl: "https://milt.movebe.app",
		apiUrlLocal: "http://localhost:5001/movebe-milt/us-central1/api",
		firebaseConfig: {
			apiKey: "AIzaSyDMtmhEKa-KZW4jNNaJC_04DkXc0p5OnEE",
			authDomain: "movebe-milt.firebaseapp.com",
			databaseURL: "https://movebe-milt.firebaseio.com",
			messagingSenderId: "564658094675",
			projectId: "movebe-milt",
			storageBucket: "movebe-milt.appspot.com",
		},
		isProduction: false,
		logErrors: false,
	},
	prod: {
		apiUrl: "https://movebe.app",
		apiUrlLocal: "http://localhost:5001/movebe-prod/us-central1/api",
		firebaseConfig: {
			apiKey: "AIzaSyBBagz8-WMMdNjc-a0omwp-1h2Nf5V7uT4",
			authDomain: "movebe-prod.firebaseapp.com",
			databaseURL: "https://movebe-prod.firebaseio.com",
			messagingSenderId: "663487173514",
			projectId: "movebe-prod",
			storageBucket: "movebe-prod.appspot.com",
		},
		isProduction: true,
		logErrors: true,
	},
	qa: {
		apiUrl: "https://qa.movebe.app",
		apiUrlLocal: "http://localhost:5001/movebe-qa/us-central1/api",
		firebaseConfig: {
			apiKey: "AIzaSyCLJ0JBwz4sRouBgh-F2s7cT01PyNVRIm4",
			authDomain: "movebe-qa.firebaseapp.com",
			databaseURL: "https://movebe-qa.firebaseio.com",
			messagingSenderId: "234235741919",
			projectId: "movebe-qa",
			storageBucket: "movebe-qa.appspot.com",
		},
		isProduction: false,
		logErrors: true,
	},
};
