import {Component} from "@angular/core";
import {select, Store} from "@ngrx/store";
import {BehaviorSubject} from "rxjs/BehaviorSubject";
import {Observable} from "rxjs/Observable";
import {map} from "rxjs/operators";
import * as fromMerchant from "../+state/index";
import {Offer, OfferStatus} from "../../../../../lib/model/offer.model";
import {MovebeState} from "../../../app/movebe-state.model";
import {EffectsService} from "../../../core/effects/effects.service";
import {OfferScanRequestParams} from "../../../core/scan-request/scan-request-params.model";

@Component({
	selector: "movebe-merchants-email-offers",
	templateUrl: "./sms-offers.page.html",
})
export class SmsOffersPage {
	readonly currentMerchantId$: Observable<string | undefined>;
	readonly offers$: Observable<Offer[]>;
	selectedOfferId$ = new BehaviorSubject<string | null>("");
	telephoneNumber = "";
	scanRequestParams: OfferScanRequestParams;

	constructor(
		private effectsService: EffectsService,
		private store: Store<MovebeState>
	) {
		this.currentMerchantId$ = this.store.pipe(
			select(fromMerchant.getCurrentMerchantId)
		);
		this.offers$ = this.store.pipe(
			select(fromMerchant.getMerchantOffers),
			map(offers =>
				offers
					.sort((a, b) => a.sortPosition - b.sortPosition)
					.filter(offer => offer.status === OfferStatus.activated)
			)
		);
	}

	playClick() {
		this.effectsService.offerClick();
	}

	rewardIssued(rewardId: string) {
		this.effectsService.reward();
		//this.reward$ = this.rewardsService.getReward(rewardId);
	}

	selectOffer(offerId: string) {
		this.currentMerchantId$.take(1).subscribe(merchantId => {
			this.scanRequestParams = {
				merchantId: merchantId!,
				offerId,
			};
			this.selectedOfferId$.next(null);
			// next() is wrapped in setTimeout() to ensure component gets destroyed and reinstantiated
			setTimeout(() => {
				this.selectedOfferId$.next(offerId);
			});
			this.effectsService.offerClick();
		});
	}

	sendSms() {
		//TODO: confirmation popup
	}
}
