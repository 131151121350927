import {Component, OnDestroy} from "@angular/core";
import {ModalController} from "@ionic/angular";
import {merge, Subject, timer} from "rxjs";
import {Observable} from "rxjs/Observable";
import {first, map, switchMap, takeUntil} from "rxjs/operators";
import {Blotter} from "../../../../../lib/model/blotter.model";
import {StakeholderBalances} from "../../../../../lib/model/merchant-balance.model";
import {Merchant} from "../../../../../lib/model/merchant.model";
import {Logger} from "../../../core/logger/logger.service";
import {MerchantsService} from "../../../core/merchants/merchants.service";
import {MovebeApiService} from "../../../core/movebe-api/movebe-api.service";
import {childJoin} from "../../../lib/rxjs-operators/child-join";
import {filterNulls} from "../../../lib/rxjs-operators/filter-nulls";
import {StakeholderModal} from "../stakeholder.modal/stakeholder.modal";

export interface AccountBalance {
	booked: {amount: number; currency_code: string};
	id: string;
	pending: {amount: number; currency_code: string};
	stakeholderName: string;
	type: string;
}

export interface MovebeRevenue {
	day: number;
	month: number;
	week: number;
}

export enum AccountBalanceType {
	merchant = "merchant",
	movebe = "movebe",
	provider = "provider",
}

@Component({
	selector: "page-admin-transactions",
	styleUrls: ["./transactions.page.scss"],
	templateUrl: "./transactions.page.html",
})
export class TransactionsPage implements OnDestroy {
	readonly transactionsLimit = 10;
	readonly refreshInterval = 60000;
	readonly done$: Subject<void> = new Subject<void>();
	readonly manualRefresh$ = new Subject<void>();
	readonly movebeRevenue$: Observable<MovebeRevenue[]>;
	readonly providerBalances$: Observable<AccountBalance[]>;
	readonly recentTransactions$: Observable<Blotter[]>;
	readonly merchantBalances$: Observable<
		{merchant: Merchant; balances: StakeholderBalances}[]
	>;

	constructor(
		private logger: Logger,
		private modalCtrl: ModalController,
		private merchantsService: MerchantsService,
		private movebeApiService: MovebeApiService
	) {
		this.merchantBalances$ = this.merchantsService.getMerchantBalances().pipe(
			childJoin<
				StakeholderBalances,
				Merchant,
				{merchant: Merchant; balances: StakeholderBalances}
			>(
				(balances: StakeholderBalances) =>
					this.merchantsService.getMerchant(balances.$key!).pipe(filterNulls()),
				(balances: StakeholderBalances, merchant: Merchant) => {
					return {balances, merchant};
				}
			)
		);

		const loadDataTrigger$ = merge(
			timer(0, this.refreshInterval),
			this.manualRefresh$
		)
			.pipe(takeUntil(this.done$))
			.shareReplay(1);

		this.movebeRevenue$ = loadDataTrigger$.pipe(
			switchMap(() => this.movebeApiService.getMovebeRevenue())
		);

		this.providerBalances$ = loadDataTrigger$.pipe(
			switchMap(() => this.movebeApiService.getStakeholdersBalances()),
			map(results =>
				results.filter(result => result.type === AccountBalanceType.provider)
			)
		);

		this.recentTransactions$ = loadDataTrigger$.pipe(
			switchMap(() =>
				this.movebeApiService.getRecentTransactions(this.transactionsLimit)
			)
		);
	}

	ngOnDestroy() {
		this.done$.next();
		this.done$.complete();
	}

	showStakeholderDetails(type, stakeholderId) {
		this.recentTransactions$
			.pipe(
				first(),
				map(transactions =>
					transactions.filter(
						transaction =>
							(type === "merchant" &&
								transaction.merchantId === stakeholderId) ||
							(type === "provider" && transaction.providerId === stakeholderId)
					)
				),
				switchMap(stakeholderTransactions =>
					this.modalCtrl.create({
						component: StakeholderModal,
						componentProps: {
							stakeholderTransactions,
							type,
						},
					})
				)
			)
			.subscribe(modal => modal.present());
	}
}
