import {
	AfterViewInit,
	Component,
	ContentChildren,
	ElementRef,
	EventEmitter,
	Input,
	OnDestroy,
	Output,
	QueryList,
	ViewChild,
} from "@angular/core";
import {combineLatest} from "rxjs";
import {Observable} from "rxjs/Observable";
import {
	auditTime,
	debounceTime,
	delay,
	distinctUntilChanged,
	filter,
	map,
	shareReplay,
	switchMap,
	takeUntil,
	withLatestFrom,
} from "rxjs/operators";
import {Subject} from "rxjs/Subject";
import {ScrollListItemComponent} from "./scroll-list-item.component";

@Component({
	selector: "movebe-scroll-list",
	styleUrls: ["scroll-list.component.scss"],
	templateUrl: "scroll-list.component.html",
})
export class ScrollListComponent implements AfterViewInit, OnDestroy {
	//TODO: resolve bug where scroll highlighting stops working after clicking around several times

	private readonly done$ = new Subject<void>();
	itemOffsets$ = new Observable<number[]>();
	readonly listScrolled$ = new Subject<HTMLElement>();
	readonly scrollTo$ = new Subject<number>();
	readonly scrollTop$ = new Subject<number>();
	private readonly offsetDebounceTimeMilliseconds = 50;
	private readonly scrollItemComputeIntervalMilliseconds = 100;

	@Output()
	scrolledToNumber = new EventEmitter<number>(); //the index number of the item currently scrolled to

	@ContentChildren(ScrollListItemComponent)
	scrollListItems: QueryList<ScrollListItemComponent>;

	@ViewChild("scrollElement")
	scrollElement: ElementRef;

	@Input() /*tslint:disable-line:no-unsafe-any*/
	set scrollTo(itemNumber: number) {
		this.scrollTo$.next(itemNumber);
	}

	ngAfterViewInit() {
		//a list of offset heights for each item in the current list
		this.itemOffsets$ = this.scrollListItems.changes.pipe(
			switchMap((
				items: QueryList<ScrollListItemComponent> //ensure the offset heights are taken after items have been rendered/sized
			) =>
				combineLatest(
					items.map(item =>
						item.resized.pipe(map(x => item.nativeElement.offsetTop))
					)
				)
			),
			debounceTime(this.offsetDebounceTimeMilliseconds), // because each item in the list gets resized individually in rapid succession and we only care about the final value
			shareReplay(1)
		);

		//whenever the list changes, reset the scroll to top
		this.scrollListItems.changes
			.pipe(
				takeUntil(this.done$),
				delay(0) /// .delay(0) avoids ExpressionChangedAfterItHasBeenCheckedError. (see: https://goo.gl/vDazKY)
			)
			.subscribe(items => {
				(this.scrollElement.nativeElement as HTMLElement).scrollTop = 0; //scroll the list back to the top
				this.scrolledToNumber.emit(0); //select the first item in the list
			});

		//whenever the user scrolls the list, calculate the selected item based on scroll height
		combineLatest(
			this.listScrolled$.pipe(
				auditTime(this.scrollItemComputeIntervalMilliseconds)
			), //because scroll events fire much more rapidly than we need to calculate the scrolledToNumber
			this.itemOffsets$.pipe(filter((offsets: number[]) => offsets.length > 0))
		)
			.pipe(
				map(([scrolledElement, offsets]: [HTMLElement, number[]]) =>
					offsets.findIndex(offset => offset >= scrolledElement.scrollTop)
				),
				distinctUntilChanged(), //because we don't need an updated value if the item hasn't changed due to scrolling a short distance
				delay(0), // .delay(0) avoids ExpressionChangedAfterItHasBeenCheckedError. (see: https://goo.gl/vDazKY)
				takeUntil(this.done$)
			)
			.subscribe(scrolledItemIndex => {
				this.scrolledToNumber.emit(scrolledItemIndex);
			});

		//whenever scrollTo input value changes to a new value n, scroll to the nth item in the list
		this.scrollTo$
			.pipe(
				withLatestFrom(
					this.itemOffsets$,
					(selected: number, offsets: number[]) => offsets[selected]
				), //find the scroll offset of the selected item
				takeUntil(this.done$)
			)
			.subscribe(
				scrollTop => this.scrollTop$.next(scrollTop) //triggers scrolling via the [scrollTop] binding in the template
			);
	}

	ngOnDestroy() {
		this.done$.next();
		this.done$.complete();
	}
}
