import {action, payload} from "ts-action";
import {Employer} from "../../../../../../lib/model/employment-relationship.model";

export namespace CurrentUserActions {
	export const QueryEmployers = action(
		"[Associated Merchants] Query Employers"
	);

	export const EmployersReceived = action(
		"[Associated Merchants] Employers Received",
		payload<Employer[]>()
	);
}
