import {FirebaseEntity} from "../firebase-entity.model";
import {SurveyChoice} from "./survey-choice.model";

export enum SurveyQuestionType {
	freeform = "freeform",
	multipleChoice = "multiple-choice",
}

export interface SurveyQuestion extends FirebaseEntity {
	choices?: SurveyChoice[];
	id?: string;
	isSatisfactionIndicator: boolean;
	placeholder?: string;
	required: boolean;
	text: string;
	type: SurveyQuestionType;
}
