import {MerchantRole} from "../../../../lib/model/employment-relationship.model";
import {FirebaseEntity} from "../../../../lib/model/firebase-entity.model";

export interface UserInvitation extends FirebaseEntity {
	displayName: string;
	email: string;
	location?: string;
	phoneNumber: string;
	role: MerchantRole;
	status: UserInvitationStatus;
	universal: boolean;
}

export enum UserInvitationStatus {
	accepted = "accepted",
	invited = "invited",
	rejected = "rejected",
	requested = "requested",
}
