import {Injectable} from "@angular/core";
import {Actions, Effect} from "@ngrx/effects";
import {Action} from "@ngrx/store";
import {Observable} from "rxjs/Observable";
import {concatMap, map, switchMap} from "rxjs/operators";
import {ofType, toPayload} from "ts-action-operators";
import {MerchantStatus} from "../../../../../lib/model/merchant.model";
import {Logger} from "../../../core/logger/logger.service";
import {MerchantLocationOffers} from "../../../core/offer-search/merchant-location-offers.model";

import {OfferSearchService} from "../../../core/offer-search/offer-search.service";
import {GeoSearchCriteria} from "../../geo-search/geo-search-criteria.model";
import {OfferSearchActions} from "./offer-search.actions";

@Injectable()
export class OfferSearchEffects {
	@Effect()
	readonly initializeSearchQueryEffect$: Observable<Action>;
	@Effect()
	readonly searchOffersEffect$: Observable<Action>;
	@Effect()
	readonly updateSearchCriteriaEffect$: Observable<Action>;

	constructor(
		private actions$: Actions,
		private logger: Logger,
		private offerSearchService: OfferSearchService
	) {
		this.searchOffersEffect$ = actions$.pipe(
			ofType(OfferSearchActions.SearchOffers),
			toPayload(),
			concatMap(searchCriteria => [
				new OfferSearchActions.InitializeSearchQuery(searchCriteria),
				new OfferSearchActions.SearchCriteriaReceived(searchCriteria),
			])
		);

		this.initializeSearchQueryEffect$ = actions$.pipe(
			ofType(OfferSearchActions.InitializeSearchQuery),
			toPayload(),
			switchMap(geoQuerySearchCriteria =>
				this.searchMerchantLocations(geoQuerySearchCriteria)
			),
			map((searchResults: MerchantLocationOffers[]) =>
				searchResults.filter(locationOffer => {
					if (locationOffer.merchant) {
						return (
							locationOffer.merchant.status === MerchantStatus.approved &&
							locationOffer.location &&
							!locationOffer.location.hidden &&
							(locationOffer.merchant.type === "parking_lot" ||
								locationOffer.offers.length > 0 ||
								locationOffer.rewards.length > 0)
						);
					} else {
						this.logger.error("Unexpected offer missing merchant");
						return false;
					}
				})
			),
			map(
				(searchResults: MerchantLocationOffers[]) =>
					new OfferSearchActions.SearchResultsReceived(searchResults)
			)
		);

		this.updateSearchCriteriaEffect$ = actions$.pipe(
			ofType(OfferSearchActions.UpdateSearchCriteria),
			toPayload(),
			map((searchCriteria: GeoSearchCriteria) => {
				this.updateSearchCriteria(searchCriteria);
				return new OfferSearchActions.SearchCriteriaReceived(searchCriteria);
			})
		);
	}

	searchMerchantLocations(
		searchCriteria: GeoSearchCriteria
	): Observable<MerchantLocationOffers[]> {
		return this.offerSearchService.initializeOfferSearch(searchCriteria);
	}

	updateSearchCriteria(searchCriteria: GeoSearchCriteria) {
		this.offerSearchService.updateSearchCriteria(searchCriteria);
	}
}
