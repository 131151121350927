import {Component, Input} from "@angular/core";

@Component({
	selector: "movebe-marker-chip",
	styleUrls: ["marker-chip.component.scss"],
	templateUrl: "marker-chip.component.html",
})
export class MarkerChipComponent {
	@Input()
	markerCode: string;
}
