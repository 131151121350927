import {Component, Input, OnInit} from "@angular/core";
import {Observable} from "rxjs/Observable";
import {Merchant} from "../../../../../lib/model/merchant.model";
import {Reward} from "../../../../../lib/model/reward.model";
import {MerchantsService} from "../../../core/merchants/merchants.service";
import {RewardsService} from "../../../core/rewards/rewards.service";
import {
	RewardScanRequestParams,
	RewardScanRequestResponse,
} from "../../../core/scan-request/scan-request-params.model";
import {ScanRequest} from "../../../core/scan-request/scan-request.model";
import {filterNulls} from "../../../lib/rxjs-operators/filter-nulls";
import {ScanRequestReviewComponent} from "./scan-request-review-component.model";

@Component({
	selector: "movebe-reward-scan-request-review",
	templateUrl: "./reward-scan-request-review.component.html",
})
export class RewardScanRequestReviewComponent
	implements OnInit, ScanRequestReviewComponent {
	@Input()
	scanRequest: ScanRequest;

	reward$: Observable<Reward>;
	merchant$: Observable<Merchant>;

	constructor(
		private rewardsService: RewardsService,
		private merchantsService: MerchantsService
	) {}

	ngOnInit(): void {
		const requestParams = this.scanRequest
			.requestParams as RewardScanRequestParams;

		this.reward$ = this.rewardsService
			.getReward(requestParams.rewardId)
			.pipe(filterNulls());

		this.merchant$ = this.merchantsService
			.getMerchant(requestParams.merchantId)
			.pipe(filterNulls());
	}

	approve(): Promise<RewardScanRequestResponse> {
		return this.reward$
			.first()
			.toPromise()
			.then(reward => this.rewardsService.setRewardRedeemed(reward))
			.then(() => ({}));
	}
}
