import {Action} from "@ngrx/store";
import {isType} from "ts-action";
import {MerchantLocationOffers} from "../../../core/offer-search/merchant-location-offers.model";
import {GeoSearchCriteria} from "../../geo-search/geo-search-criteria.model";
import {OfferSearchActions} from "./offer-search.actions";

export interface OfferSearchState {
	searchCriteria?: GeoSearchCriteria;
	searchResults: MerchantLocationOffers[];
}

const defaultState: OfferSearchState = {
	searchResults: null,
};

export function offerSearchReducer(
	state: OfferSearchState = defaultState,
	action: Action
): OfferSearchState {
	if (isType(action, OfferSearchActions.SearchResultsReceived)) {
		return {...state, searchResults: action.payload};
	}
	if (isType(action, OfferSearchActions.SearchCriteriaReceived)) {
		return {...state, searchCriteria: action.payload};
	}
	if (isType(action, OfferSearchActions.ClearSearchResults)) {
		return {searchResults: null};
	}
	return state;
}

export const getSearchResults = (state: OfferSearchState) =>
	state.searchResults;
