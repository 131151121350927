export enum EnvironmentName {
	build = "build",
	demo = "demo",
	joey = "joey",
	milt = "milt",
	master = "master",
	prod = "prod",
	qa = "qa",
}

export interface BuildConfiguration {
	environment: EnvironmentName;
	useLocalApi: boolean;
}
