import {Component} from "@angular/core";
import {NavParams} from "@ionic/angular";
import {Observable} from "rxjs/Observable";
import {Reward} from "../../../../../lib/model/reward.model";

@Component({
	selector: "page-mb-reward",
	templateUrl: "reward.page.html",
})
export class RewardPage {
	reward$: Observable<Reward>;

	constructor(private navParams: NavParams) {
		this.reward$ = navParams.get("reward") as Observable<Reward>;
	}
}
