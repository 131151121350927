import {Component} from "@angular/core";
import {Nav, NavController} from "@ionic/angular";
import {Logger} from "../../../core/logger/logger.service";
import {MerchantLocationOffers} from "../../../core/offer-search/merchant-location-offers.model";
import {MerchantLocationPage} from "../merchant-location/merchant-location.page";
import {OfferPage} from "../offer/offer.page";

@Component({
	selector: "page-mb-find",
	templateUrl: "find.page.html",
})
export class FindPage {
	constructor(
		private nav: Nav,
		private navCtrl: NavController,
		private logger: Logger
	) {}

	async merchantLocationSelected(
		merchantLocationOffers: MerchantLocationOffers
	) {
		const active = await this.nav.getActive();
		if (
			merchantLocationOffers.offers.length === 1 &&
			merchantLocationOffers.rewards.length === 0
		) {
			this.nav.push(OfferPage, {
				location: merchantLocationOffers.location,
				offer: merchantLocationOffers.offers[0],
			});
		} else {
			this.nav.push(MerchantLocationPage, {
				location: merchantLocationOffers.location,
			});
		}
	}
}
