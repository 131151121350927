import {Component, EventEmitter, Input, Output} from "@angular/core";
import {Observable} from "rxjs/Observable";
import {BenefitType} from "../../../../lib/model/benefit.model";
import {IssuedReward} from "../../../../lib/model/reward.model";
import {OffersService} from "../../core/offers/offers.service";

@Component({
	selector: "movebe-reward-list",
	styleUrls: ["reward-list.component.html"],
	templateUrl: "reward-list.component.html",
})
export class RewardListComponent {
	BenefitType = BenefitType;
	@Input()
	differentiateExpired = false;
	@Input()
	emptyListMessage: string;
	@Input()
	rewards$: Observable<IssuedReward[]>;
	@Input()
	title: string;
	@Output()
	rewardSelected = new EventEmitter<IssuedReward>();

	constructor(private offersService: OffersService) {}

	itemClicked(reward: IssuedReward) {
		this.rewardSelected.emit(reward);
	}
}
