import {Component, OnInit} from "@angular/core";
import {AlertController, Nav} from "@ionic/angular";
import {select, Store} from "@ngrx/store";
import {TranslateService} from "@ngx-translate/core";
import {Observable} from "rxjs/Observable";
import {filter, first} from "rxjs/operators";
import * as fromMerchant from "../+state/index";
import {Offer, OfferStatus} from "../../../../../lib/model/offer.model";
import {MovebeState} from "../../../app/movebe-state.model";
import {Logger} from "../../../core/logger/logger.service";
import {OffersService} from "../../../core/offers/offers.service";
import {collapseHorizontal} from "../../../shared/animations/animations";
import {OfferPage} from "../offer/offer.page";

@Component({
	animations: [collapseHorizontal],
	selector: "page-mm-offers",
	styleUrls: ["./offers.page.scss"],
	templateUrl: "./offers.page.html",
})
export class OffersPage implements OnInit {
	readonly currentMerchantId$: Observable<string | undefined>;
	readonly offers$: Observable<Offer[]>;
	offersPublished: Offer[];
	offersUnpublished: Offer[];

	constructor(
		private logger: Logger,
		private nav: Nav,
		private offersService: OffersService,
		private store: Store<MovebeState>,
		private alertCtrl: AlertController,
		private translate: TranslateService
	) {
		this.currentMerchantId$ = this.store.pipe(
			select(fromMerchant.getCurrentMerchantId)
		);
		this.offers$ = this.store.pipe(select(fromMerchant.getMerchantOffers));

		this.offers$
			.pipe(
				first(),
				filter(offers => offers.length === 0)
			)
			.subscribe(() => this.addNewOffer());
	}

	ngOnInit() {
		this.offers$.subscribe((offers: Offer[]) => {
			this.offersPublished = offers
				.filter(offer => offer.status === OfferStatus.activated)
				.sort((a, b) => a.sortPosition - b.sortPosition);
			this.offersUnpublished = offers.filter(offer =>
				[OfferStatus.new, OfferStatus.deactivated].includes(offer.status)
			);
		});
	}

	/*tslint:disable:member-ordering*/

	async deleteOfferClicked(offer: Offer) {
		const alert = await this.alertCtrl.create({
			buttons: [
				{
					cssClass: "secondary",
					role: "cancel",
					text: this.translate.instant("LABEL.CANCEL"),
				},
				{
					handler: () => {
						this.deleteOffer(offer);
					},
					text: this.translate.instant("LABEL.DELETE"),
				},
			],
			header: this.translate.instant("OFFERS.DELETE"),
			message: this.translate.instant("OFFERS.DELETE_EXPLAIN"),
			subHeader: this.translate.instant("OFFERS.DELETE_CONFIRM"),
		});
		await alert.present();
	}

	deleteOffer(offer: Offer) {
		this.currentMerchantId$.pipe(first()).subscribe(merchantId => {
			this.offersService.deleteOffer(merchantId!, offer);
		});
	}

	async archiveOfferClicked(offer: Offer) {
		const alert = await this.alertCtrl.create({
			buttons: [
				{
					cssClass: "secondary",
					role: "cancel",
					text: this.translate.instant("LABEL.CANCEL"),
				},
				{
					handler: () => {
						this.archiveOffer(offer);
					},
					text: this.translate.instant("LABEL.ARCHIVE"),
				},
			],
			header: this.translate.instant("OFFERS.ARCHIVE"),
			message: this.translate.instant("OFFERS.ARCHIVE_EXPLAIN"),
			subHeader: this.translate.instant("OFFERS.ARCHIVE_CONFIRM"),
		});
		await alert.present();
	}

	archiveOffer(offer: Offer) {
		offer.status = OfferStatus.archived;
		this.updateOffer(offer);
	}

	activateOfferClicked(offer: Offer) {
		if (offer.status === OfferStatus.new) {
			this.confirmActivate(offer);
		} else {
			this.activateOffer(offer);
		}
	}

	async confirmActivate(offer: Offer) {
		const alert = await this.alertCtrl.create({
			buttons: [
				{
					cssClass: "secondary",
					role: "cancel",
					text: this.translate.instant("LABEL.CANCEL"),
				},
				{
					handler: () => {
						this.activateOffer(offer);
					},
					text: this.translate.instant("LABEL.ACTIVATE"),
				},
			],
			header: this.translate.instant("OFFERS.ACTIVATE"),
			message: this.translate.instant("OFFERS.ACTIVATE_EXPLAIN"),
			subHeader: this.translate.instant("OFFERS.ACTIVATE_CONFIRM"),
		});
		await alert.present();
	}

	activateOffer(offer: Offer) {
		const highestSortPosition = this.offersPublished
			.map(o => o.sortPosition)
			.reduce((prev: number, curr: number) => Math.max(prev, curr), -1);
		offer.sortPosition = highestSortPosition + 1;
		offer.status = OfferStatus.activated;
		this.updateOffer(offer);
	}

	addNewOffer(): void {
		this.nav.push(OfferPage);
	}

	deactivateOffer(offer: Offer) {
		const highestSortPosition = this.offersPublished
			.map(o => o.sortPosition)
			.reduce((prev: number, curr: number) => Math.max(prev, curr || -1), -1);
		for (let i = offer.sortPosition; i <= highestSortPosition; i++) {
			if (this.offersPublished[i]) {
				this.offersPublished[i].sortPosition--;
			}
			this.updateOffer(this.offersPublished[i]);
		}
		offer.status = OfferStatus.deactivated;
		offer.sortPosition = -1;
		this.updateOffer(offer);
	}

	editOffer(offerId: string): void {
		this.currentMerchantId$.take(1).subscribe(merchantId => {
			this.nav.push(OfferPage, {offerId});
		});
	}

	offerMoved(fromTo: {from: number; to: number}) {
		const movedOffer = this.offersPublished[fromTo.from];
		this.offersPublished.splice(fromTo.from, 1);
		this.offersPublished.splice(fromTo.to, 0, movedOffer);
		this.currentMerchantId$.take(1).subscribe(merchantId => {
			this.offersPublished.forEach((offer, i) => {
				offer.sortPosition = i;
				this.offersService.updateOffer(merchantId!, offer);
			});
		});
	}

	updateOffer(offer) {
		this.currentMerchantId$.pipe(first()).subscribe(merchantId => {
			this.offersService.updateOffer(merchantId!, offer);
		});
	}
}
