import {Component, Input, OnInit, ViewChild} from "@angular/core";
import {NguiMapComponent} from "@ngui/map";
import {MerchantLocation} from "../../../../lib/model/merchant-location.model";
import {MappingService} from "../../core/mapping/mapping.service";
import {movebeMapStyles} from "../map/map-styles.data";

//TODO: implement using native maps for mobile

@Component({
	selector: "movebe-location-map",
	styleUrls: ["location-map.component.scss"],
	templateUrl: "location-map.component.html",
})
export class LocationMapComponent implements OnInit {
	@Input()
	isMerchant: boolean;
	@Input()
	location: MerchantLocation;
	@ViewChild(NguiMapComponent)
	map: NguiMapComponent;
	mapOptions;
	mapStyles = movebeMapStyles;
	marker: string;
	markerIcon: string;

	constructor(private mappingService: MappingService) {}

	ngOnInit() {
		this.mapOptions = {
			center: this.location.geo,
			disableDefaultUI: true,
			styles: movebeMapStyles,
			zoom: 14,
		};
		//TODO: ensure map marker is aligned center rather than bottom center
		this.markerIcon = this.isMerchant
			? this.mappingService.getMerchantMarkerUrl()
			: this.mappingService.getParkingMarkerUrl();
	}
}
