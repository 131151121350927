import {Component} from "@angular/core";
import {NavParams} from "@ionic/angular";
import {select, Store} from "@ngrx/store";
import {combineLatest, Observable} from "rxjs";
import {filter, flatMap, map} from "rxjs/operators";
import * as fromMerchant from "../+state";
import {
	IssuedReward,
	RewardStatus,
} from "../../../../../lib/model/reward.model";
import {SurveyQuestionType} from "../../../../../lib/model/survey/survey-question.model";
import {UserProfile} from "../../../../../lib/model/user/user-profile.model";
import {MovebeState} from "../../../app/movebe-state.model";
import {Validation} from "../../../core/offers/validation.model";
import {RewardsService} from "../../../core/rewards/rewards.service";
import {UsersService} from "../../../core/user/users.service";
import {filterNulls} from "../../../lib/rxjs-operators/filter-nulls";

@Component({
	selector: "page-mm-reward",
	styleUrls: ["./reward.page.scss"],
	templateUrl: "./reward.page.html",
})
export class RewardPage {
	SurveyQuestionType = SurveyQuestionType;

	readonly reward$: Observable<IssuedReward>;
	readonly validationDetails$: Observable<
		{agent: UserProfile; validation: Validation}[]
	>;
	readonly merchantName$: Observable<string>;

	constructor(
		private rewardsService: RewardsService,
		private navParams: NavParams,
		private usersService: UsersService,
		private store: Store<MovebeState>
	) {
		this.merchantName$ = this.store.pipe(
			select(fromMerchant.getCurrentMerchant),
			filterNulls(),
			map(merchant => merchant.name)
		);

		const rewardId = this.navParams.get("rewardId") as string;
		this.reward$ = this.rewardsService
			.getReward(rewardId)
			.pipe(
				filter(reward => reward.status !== RewardStatus.pending)
			) as Observable<IssuedReward>;

		this.validationDetails$ = this.reward$.pipe(
			flatMap(reward =>
				combineLatest(
					reward.validations.map(validation =>
						this.usersService
							.getUser(validation.agentId)
							.pipe(map(agent => ({agent, validation})))
					)
				)
			)
		);
	}

	// converts a sentiment score fom Google Natural Language API  [-1...1] to a satisfaction score (1,2,3,4) used to show satisfaction icons
	private sentimentScoreToSatisfaction(score: number): number {
		return Math.round((score + 1) * 1.5) + 1; //tslint:disable-line:no-magic-numbers
	}
}
