import {Pipe, PipeTransform} from "@angular/core";
import {MappingService} from "../../core/mapping/mapping.service";

@Pipe({name: "movebeBase26"})
export class Base26Pipe implements PipeTransform {
	constructor(private mappingService: MappingService) {}

	transform(n: number): string {
		return this.mappingService.toBase26(n);
	}
}
