import {ErrorHandler, Injectable, Injector} from "@angular/core";
import {AlertController} from "@ionic/angular";
import {Actions} from "@ngrx/effects";
import {select, Store} from "@ngrx/store";
import {TranslateService} from "@ngx-translate/core";
import * as Sentry from "@sentry/browser";
import {Observable} from "rxjs";
import {UserProfile} from "../../../lib/model/user/user-profile.model";
import {appEnvironment} from "./app-environment";
import {MovebeState} from "./movebe-state.model";

@Injectable()
export class ErrorHandlerService implements ErrorHandler {
	readonly userProfile$: Observable<UserProfile>;

	constructor(
		private actions$: Actions,
		private alertCtrl: AlertController,
		private translate: TranslateService,
		private injector: Injector
	) {
		if (appEnvironment.logErrors) {
			Sentry.init({
				attachStacktrace: true,
				dsn: "https://ebec6d34f5104c279cfdb837783bec13@sentry.io/296180",
				environment: appEnvironment.firebaseConfig.projectId,
			});
			this.actions$.subscribe((action: any) => {
				Sentry.addBreadcrumb({
					category: "ngrx",
					message: action.type,
					...(action.payload
						? {data: {payload: JSON.stringify(action.payload)}}
						: {}),
				});
			});
			Sentry.configureScope(scope => {
				scope.setTag("projectId", appEnvironment.firebaseConfig.projectId);
				scope.setExtra("apiUrl", appEnvironment.apiUrl);
				scope.setExtra("isProduction", appEnvironment.isProduction);
				setTimeout(() => {
					this.injector
						.get(Store)
						.pipe(select((state: MovebeState) => state.user.profile))
						.subscribe((userProfile: UserProfile) => {
							scope.setUser({
								email: userProfile.email,
								id: userProfile.$key,
								username: userProfile.displayName,
							});
							scope.setTag("appMode", userProfile.appMode);
							scope.setTag("language", userProfile.language);
							if (userProfile.currentMerchant) {
								scope.setTag(
									"merchant",
									userProfile.currentMerchant.merchant.key
								);
								scope.setTag(
									"merchant.name",
									userProfile.currentMerchant.merchant.name
								);
								scope.setTag(
									"merchant.role",
									userProfile.currentMerchant.employmentRelationship.role
								);
							}
						});
				});
			});
		}
	}

	handleError(err: any): void {
		console.error(err);
		Sentry.captureException(err);
		// after logging to Sentry, don't show alert user to error that sometimes occurs when closing modals.
		// see: https://github.com/ionic-team/ionic/issues/15349
		// TODO:  remove this if the error goes away on it's own after upgrading to a newer version of Ionic.
		if (err.message === "Uncaught (in promise): overlay does not exist") {
			return;
		}
		this.alertCtrl
			.create({
				buttons: [
					{
						handler: this.restartMoveBe,
						text: this.translate.instant("ERROR.RESTART_MOVEBE"),
					},
					{
						text: this.translate.instant("ERROR.CONTINUE_WITHOUT_RESTARTING"),
					},
				],
				header: this.translate.instant("ERROR.OOPS"),
				message: this.translate.instant("ERROR.MOVE_BE_HAS_BEEN_NOTIFIED"),
				subHeader: this.translate.instant("ERROR.AN_ERROR_HAS_OCCURRED"),
			})
			.then(alert => alert.present());
	}

	restartMoveBe() {
		window.location.reload();
	}
}
