import {Component} from "@angular/core";
import {NavParams} from "@ionic/angular";
import {Observable} from "rxjs/Observable";
import {MovebeMarkersService} from "../../../core/movebe-markers/movebe-markers.service";

@Component({
	selector: "page-mb-marker",
	styleUrls: ["./marker.page.scss"],
	templateUrl: "marker.page.html",
})
export class MarkerPage {
	readonly markerScan$: Observable<any>;

	constructor(
		private navParams: NavParams,
		private markersService: MovebeMarkersService
	) {
		const scanId = this.navParams.get("scanId") as string;
		this.markerScan$ = this.markersService.getMarkerScan(scanId);
	}
}
