import {createFeatureSelector} from "@ngrx/store";
import {MerchantMembershipEffects} from "./merchant-membership.effects";
import * as fromMerchantMembershipStore from "./merchant-membership.reducer";

export const featureName = "merchantMembership";

export interface FeatureState {
	merchantMembership: MerchantMembershipState; //the name of this property must match the value of featureName const above
}

export type MerchantMembershipState = fromMerchantMembershipStore.MerchantMembershipState;

export const reducer = fromMerchantMembershipStore.merchantMembershipReducer;
export const effects = [MerchantMembershipEffects];

export const getChatState = createFeatureSelector<MerchantMembershipState>(
	featureName
);
