import {Component} from "@angular/core";
import {Observable} from "rxjs/Observable";
import {MerchantsService} from "../../../core/merchants/merchants.service";
import {DisbursementPage} from "../disbursement/disbursement.page";
import {MerchantsPage} from "../merchants/merchants.page";
import {SettlementPage} from "../settlement/settlement.page";
import {TransactionsPage} from "../transactions/transactions.page";

@Component({
	templateUrl: "./app.page.html",
})
export class AppPage {
	readonly disbursementPageRoot = DisbursementPage;
	readonly merchantsPageRoot = MerchantsPage;
	readonly settlementPageRoot = SettlementPage;
	readonly transactionsPageRoot = TransactionsPage;
	readonly pendingMerchantCount$: Observable<number>;

	constructor(private merchantsService: MerchantsService) {
		this.pendingMerchantCount$ = this.merchantsService
			.getPendingMerchants()
			.map(merchants => merchants.length);
	}
}
