import {AbstractControl, ValidatorFn} from "@angular/forms";

export function inputsMatchValidator(
	input1Name: string,
	input2Name: string
): ValidatorFn {
	return (form: AbstractControl): {[key: string]: boolean} => {
		const input1 = form.get(input1Name);
		if (!input1) {
			throw new Error(`Cannot find form control ${input1Name}`);
		}
		const input2 = form.get(input2Name);
		if (!input2) {
			throw new Error(`Cannot find form control ${input2Name}`);
		}
		const matches = input1.value === input2.value;
		input2.setErrors(matches ? null : {noMatch: true});
		return matches ? {} : {invalid: true};
	};
}
