import {Component, OnDestroy, OnInit} from "@angular/core";
import {
	ModalController,
	NavController,
	NavParams,
	ToastController,
} from "@ionic/angular";
import {Actions} from "@ngrx/effects";
import {Store} from "@ngrx/store";
import {Subject} from "rxjs";
import {Observable} from "rxjs/Observable";
import {ofType} from "ts-action-operators";
import {MerchantMembershipActions} from "../+state/merchant-membership.actions";
import {
	Country,
	CountryCurrency,
	CountryNames,
} from "../../../../../lib/model/country.model";
import {Employee} from "../../../../../lib/model/employment-relationship.model";
import {Merchant} from "../../../../../lib/model/merchant.model";
import {MovebeState} from "../../../app/movebe-state.model";
import {MerchantTypes} from "../../../core/merchant/merchant-types";
import {MerchantsService} from "../../../core/merchants/merchants.service";
import * as fromUser from "../../../lib/user/+state";

@Component({
	selector: "page-mm-enroll-merchant",
	styleUrls: ["./enroll-merchant.modal.scss"],
	templateUrl: "./enroll-merchant.modal.html",
})
export class EnrollMerchantModal implements OnInit, OnDestroy {
	businessTypes: string[];
	merchant: Merchant;
	merchantLogo: Blob;
	readonly merchantTypes = MerchantTypes;
	readonly countryNames = CountryNames;
	newMerchantManager: Employee;
	readonly currentUserId$: Observable<string | undefined>;
	private done$ = new Subject<void>();
	readonly Country = Country;

	constructor(
		private actions$: Actions,
		private merchantsService: MerchantsService,
		private navCtrl: NavController,
		private modalController: ModalController,
		private navParams: NavParams,
		private toastCtrl: ToastController,
		private store: Store<MovebeState>
	) {
		this.currentUserId$ = store.select(fromUser.getUserId);
	}

	ngOnInit(): void {
		this.businessTypes = Object.keys(this.merchantTypes);
		this.merchant = this.navParams.get("merchant") as Merchant;
		if (!this.merchant) {
			this.merchant = this.merchantsService.getNewMerchant();
		}
		if (!this.merchant.webLinks) {
			this.merchant.webLinks = [];
		}
		this.newMerchantManager = this.navParams.get(
			"newMerchantManager"
		) as Employee;
	}

	ngOnDestroy() {
		this.done$.next();
		this.done$.complete();
	}

	/*tslint:disable:member-ordering*/
	addWebLink(): void {
		this.merchant.webLinks!.push(this.merchantsService.getNewWebLink());
	}

	deleteWebLink(index: number): void {
		this.merchant.webLinks!.splice(index, 1);
	}

	imageChanged(imageBlob: Blob) {
		this.merchantLogo = imageBlob;
	}

	/*tslint:enable:member-ordering*/
	saveMerchant() {
		this.merchant.currency = CountryCurrency[this.merchant.country];
		this.merchant.homepage = this.withHttp(this.merchant.homepage);
		this.store.dispatch(
			new MerchantMembershipActions.AddMerchant({
				logo: this.merchantLogo,
				merchant: this.merchant,
			})
		);
		this.actions$
			.pipe(ofType(MerchantMembershipActions.MerchantAdded))
			.takeUntil(this.done$)
			.subscribe(() => this.dismiss());
	}

	dismiss() {
		return this.modalController.dismiss();
	}

	withHttp(url) {
		return !/^https?:\/\//i.test(url) ? `http://${url}` : url;
	}
}
