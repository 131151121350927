import {Pipe, PipeTransform} from "@angular/core";
import {
	CountryCode,
	format,
	NumberFormat,
	TelephoneNumber,
} from "libphonenumber-js";

@Pipe({name: "movebeTelephoneNumber"})
export class TelephoneNumberPipe implements PipeTransform {
	transform(
		telephoneNumber: TelephoneNumber,
		countryCode: CountryCode,
		outputFormat: NumberFormat
	): string {
		return format(telephoneNumber, countryCode, outputFormat);
	}
}
