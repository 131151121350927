import {Directive, HostBinding, Input, OnChanges} from "@angular/core";
import {MovebeMarkersService} from "../../core/movebe-markers/movebe-markers.service";

@Directive({
	selector: "img[movebeParkingLotFloorPlan]",
})
export class ParkingLotFloorPlanDirective implements OnChanges {
	readonly BLANK_IMAGE = "assets/img/1x1.transparent.png";

	@Input()
	floorPlanId: string;
	@Input()
	imageSize: number;
	@Input()
	locationId: string;
	@Input()
	merchantId: string;

	@HostBinding("style.height")
	height = "auto";
	@HostBinding("src")
	src = this.BLANK_IMAGE;
	@HostBinding("style.width")
	width = "auto";

	constructor(private movebeMarkersService: MovebeMarkersService) {}

	ngOnChanges() {
		this.height = `${this.imageSize}px`;
		this.width = `${this.imageSize}px`;
		if (this.merchantId) {
			this.movebeMarkersService
				.getFloorPlan(this.merchantId, this.locationId, this.floorPlanId)
				.subscribe(floorPlanUrl => {
					this.src = floorPlanUrl
						? `${floorPlanUrl.url}=s${this.imageSize}`
						: this.BLANK_IMAGE;
				});
		}
	}
}
