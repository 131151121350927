import {Pipe, PipeTransform} from "@angular/core";
import * as firebase from "firebase/app";
import * as moment from "moment/moment";

@Pipe({name: "movebeIsExpired"})
export class IsExpiredPipe implements PipeTransform {
	transform(expiryDate: firebase.firestore.Timestamp): boolean {
		return moment().isAfter(expiryDate.toDate());
	}
}
