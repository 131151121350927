import {Action} from "@ngrx/store";
import {isType} from "ts-action";
import {ChatMessage} from "../../../../../lib/model/chat/chat-message.model";
import {Chat} from "../../../../../lib/model/chat/chat.model";
import {ChatActions} from "./chat.actions";

export interface ChatState {
	chat?: Chat;
	messages: ChatMessage[];
}

const defaultState: ChatState = {
	messages: [],
};

export function chatReducer(
	state: ChatState = defaultState,
	action: Action
): ChatState {
	if (isType(action, ChatActions.ChatReceived)) {
		return {...state, chat: action.payload};
	}
	if (isType(action, ChatActions.ChatMessagesReceived)) {
		return {...state, messages: action.payload};
	}
	if (isType(action, ChatActions.EndChat)) {
		return defaultState;
	}
	return state;
}

export const getChat = (state: ChatState) => state.chat;
export const getChatId = (state: ChatState) =>
	state.chat ? state.chat.$key : undefined;
export const getMessages = (state: ChatState) => state.messages;
