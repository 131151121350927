import {Component, EventEmitter, OnDestroy, Output} from "@angular/core";
import {select, Store} from "@ngrx/store";
import {TranslateService} from "@ngx-translate/core";
import {combineLatest} from "rxjs";
import {BehaviorSubject} from "rxjs/BehaviorSubject";
import {Observable} from "rxjs/Observable";
import {
	debounceTime,
	first,
	map,
	shareReplay,
	skip,
	takeUntil,
	withLatestFrom,
} from "rxjs/operators";
import {Subject} from "rxjs/Subject";
import * as fromOfferSearch from "../+state/index";
import {OfferSearchActions} from "../+state/offer-search.actions";
import {MovebeState} from "../../../app/movebe-state.model";
import {BusyService} from "../../../core/busy/busy.service";
import {LatLngLiteral} from "../../../core/mapping/lat-lng-literal";
import {MappingService} from "../../../core/mapping/mapping.service";
import {
	torontoLatitude,
	torontoLongitude,
} from "../../../core/merchants/merchants.service";
import {MerchantLocationOffers} from "../../../core/offer-search/merchant-location-offers.model";
import {collapseHorizontal} from "../../../shared/animations/animations";
import {MapMarkerType} from "../../../shared/map/map-marker-type.model";
import {MapMarker} from "../../../shared/map/map-marker.class";
import {filterNulls} from "../../rxjs-operators/filter-nulls";

@Component({
	animations: [collapseHorizontal],
	selector: "movebe-offer-search",
	styleUrls: ["offer-search.component.scss"],
	templateUrl: "offer-search.component.html",
})
export class OfferSearchComponent implements OnDestroy {
	@Output()
	merchantLocationSelected = new EventEmitter<MerchantLocationOffers>();

	mapCenter: LatLngLiteral = {lat: torontoLatitude, lng: torontoLongitude};
	currentList$: Observable<MerchantLocationOffers[]>;
	offerMarkers$: Observable<MapMarker[]>;
	parkingMarkers$: Observable<MapMarker[]>;
	readonly highlightedItem$ = new Subject<{
		itemNumber: number;
		itemType: string;
	}>();
	readonly scrollTo$ = new Subject<number>();
	readonly listMode$ = new BehaviorSubject<string>("merchant");
	readonly markerClick$ = new Subject<{itemNumber: number; itemType: string}>();
	private readonly done$: Subject<void> = new Subject<void>();
	readonly visibleLocations$: Observable<MerchantLocationOffers[]>;
	readonly bounds$ = new Subject<google.maps.LatLngBounds>();
	private readonly metersPerKilometer = 1000;
	private readonly searchDebounceTimeMilliseconds = 500;

	constructor(
		private busyService: BusyService,
		private store: Store<MovebeState>,
		private mappingService: MappingService,
		private translate: TranslateService
	) {
		const searchResults$ = this.store.pipe(
			select(fromOfferSearch.getSearchResults)
		);

		// filter out locations from the circular radius search that fall outside the rectangular map area
		this.visibleLocations$ = searchResults$.pipe(
			filterNulls(),
			withLatestFrom(this.bounds$),
			map(([searchResults, bounds]) =>
				searchResults.filter(result => bounds.contains(result.location.geo))
			)
		);

		this.busyService.setBusy(
			searchResults$
				.pipe(
					filterNulls(),
					first()
				)
				.toPromise(),
			this.translate.instant("OFFERS.SEARCHING")
		);

		const searchCriteria$ = this.bounds$.pipe(
			debounceTime(this.searchDebounceTimeMilliseconds),
			map((bounds: google.maps.LatLngBounds) => {
				const center = bounds.getCenter();
				const radiusMeters = this.mappingService.computeDistanceBetween(
					center,
					bounds.getNorthEast()
				);
				return {
					center: [center.lat(), center.lng()] as [number, number],
					radius: radiusMeters / this.metersPerKilometer,
				};
			})
		);

		searchCriteria$
			.pipe(first())
			.subscribe(searchCriteria =>
				this.store.dispatch(
					new OfferSearchActions.InitializeSearchQuery(searchCriteria)
				)
			);

		searchCriteria$
			.pipe(
				skip(1),
				takeUntil(this.done$)
			)
			.subscribe(searchCriteria =>
				this.store.dispatch(
					new OfferSearchActions.UpdateSearchCriteria(searchCriteria)
				)
			);

		const locationOffersGroupedByType$ = this.visibleLocations$.pipe(
			map((locationOffers: MerchantLocationOffers[]) =>
				locationOffers.reduce(
					(locationOffersGrouped, element) => {
						(element.merchant.type === "parking_lot"
							? locationOffersGrouped.parking
							: locationOffersGrouped.offers
						).push(element);
						return locationOffersGrouped;
					},
					{offers: [], parking: []}
				)
			),
			shareReplay(1)
		);

		this.currentList$ = combineLatest(
			locationOffersGroupedByType$,
			this.listMode$
		).pipe(
			map(
				([locationOffersGroupedByType, listMode]) =>
					listMode === "parking"
						? locationOffersGroupedByType.parking
						: locationOffersGroupedByType.offers
			),
			shareReplay(1)
		);

		this.offerMarkers$ = locationOffersGroupedByType$.pipe(
			map(locationOffersGroupedByType =>
				locationOffersGroupedByType.offers.map(
					(locationOffer: MerchantLocationOffers) =>
						new MapMarker(
							locationOffer.location.geo!,
							locationOffer.rewards.length > 0
								? MapMarkerType.voucher
								: MapMarkerType.merchant
						)
				)
			)
		);

		this.parkingMarkers$ = locationOffersGroupedByType$.pipe(
			map(locationOffersGroupedByType =>
				locationOffersGroupedByType.parking.map(
					(locationOffer: MerchantLocationOffers) =>
						new MapMarker(locationOffer.location.geo!, MapMarkerType.parking)
				)
			)
		);

		locationOffersGroupedByType$
			.pipe(takeUntil(this.done$))
			.subscribe(locationOffersGroupedByType => {
				if (this.listMode === "parking") {
					if (
						locationOffersGroupedByType.parking.length === 0 &&
						locationOffersGroupedByType.offers.length > 0
					) {
						this.listMode$.next("merchant");
					}
				} else {
					if (
						locationOffersGroupedByType.offers.length === 0 &&
						locationOffersGroupedByType.parking.length > 0
					) {
						this.listMode$.next("parking");
					}
				}
			});

		this.markerClick$.takeUntil(this.done$).subscribe(item => {
			if (this.listMode !== item.itemType) {
				this.listMode = item.itemType;
			}
			setTimeout(() => {
				// when listMode changes,
				this.scrollTo$.next(item.itemNumber);
			});
		});

		this.listMode$.takeUntil(this.done$).subscribe(() => {
			this.scrollTo$.next(1);
		});
	}

	ngOnDestroy() {
		this.store.dispatch(new OfferSearchActions.ClearSearchResults());
		this.done$.next();
		this.done$.complete();
	}

	chooseMerchantLocation(merchantLocationOffers: MerchantLocationOffers) {
		this.merchantLocationSelected.emit(merchantLocationOffers);
	}

	scrolledToMerchantItem(itemNumber: number) {
		this.highlightedItem$.next({itemNumber, itemType: this.listMode});
	}

	get listMode() {
		return this.listMode$.value;
	}

	set listMode(value) {
		this.listMode$.next(value);
	}
}
