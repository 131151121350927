/* tslint:disable:object-literal-sort-keys */

import {
	BenefitType,
	ProviderBenefit,
} from "../../../../lib/model/benefit.model";
import {Currency} from "../../../../lib/model/country.model";
import {Dictionary} from "../../../../lib/model/dictionary.model";

export const benefitData: Dictionary<ProviderBenefit> = {
	"bike-share-usd-00250": {
		type: BenefitType.bikeShare,
		amount: 2.5,
		currency: Currency.USD,
		description: "get $2.50 towards a bike share",
	},
	"bike-share-usd-00500": {
		type: BenefitType.bikeShare,
		amount: 5,
		currency: Currency.USD,
		description: "get $5.00 towards a bike share",
	},
	"ferry-usd-00250": {
		type: BenefitType.ferry,
		amount: 2.5,
		currency: Currency.USD,
		description: "get $2.50 off your your ferry fare",
	},
	"ferry-usd-00500": {
		type: BenefitType.ferry,
		amount: 5,
		currency: Currency.USD,
		description: "get $5.00 off your your ferry fare",
	},
	"ferry-usd-01000": {
		type: BenefitType.ferry,
		amount: 10,
		currency: Currency.USD,
		description: "get $10.00 off your your ferry fare",
	},
	"fuel-usd-00250": {
		type: BenefitType.fuel,
		amount: 2.5,
		currency: Currency.USD,
		description: "get $2.50 in fuel at a participating gas station",
	},
	"fuel-usd-00500": {
		type: BenefitType.fuel,
		amount: 5,
		currency: Currency.USD,
		description: "get $5.00 in fuel at a participating gas station",
	},
	"fuel-usd-05000": {
		type: BenefitType.fuel,
		amount: 50,
		currency: Currency.USD,
		description: "get $50.00 in fuel at a participating gas station",
	},
	"parking-usd-00250": {
		type: BenefitType.parking,
		amount: 2.5,
		currency: Currency.USD,
		description: "get $2.50 off your parking at a participating parking lot",
	},
	"parking-usd-00500": {
		type: BenefitType.parking,
		amount: 5,
		currency: Currency.USD,
		description: "get $5 off your parking at a participating parking lot",
	},
	"parking-usd-00750": {
		type: BenefitType.parking,
		amount: 7.5,
		currency: Currency.USD,
		description: "get $7.50 off your parking at a participating parking lot",
	},
	"parking-usd-01000": {
		type: BenefitType.parking,
		amount: 10,
		currency: Currency.USD,
		description: "get $10 off your parking at a participating parking lot",
	},
	"parking-usd-01500": {
		type: BenefitType.parking,
		amount: 15,
		currency: Currency.USD,
		description: "get $15 off your parking at a participating parking lot",
	},
	"parking-usd-02000": {
		type: BenefitType.parking,
		amount: 20,
		currency: Currency.USD,
		description: "get $20 off your parking at a participating parking lot",
	},
	"parking-usd-02500": {
		type: BenefitType.parking,
		amount: 25,
		currency: Currency.USD,
		description: "get $25 off your parking at a participating parking lot",
	},
	"parking-usd-03000": {
		type: BenefitType.parking,
		amount: 30,
		currency: Currency.USD,
		description: "get $30 off your parking at a participating parking lot",
	},
	"parking-usd-04000": {
		type: BenefitType.parking,
		amount: 40,
		currency: Currency.USD,
		description: "get $40 off your parking at a participating parking lot",
	},
	"parking-usd-05000": {
		type: BenefitType.parking,
		amount: 50,
		currency: Currency.USD,
		description: "get $50 off your parking at a participating parking lot",
	},
	"ride-hail-usd-00250": {
		type: BenefitType.rideHail,
		amount: 2.5,
		currency: Currency.USD,
		description: "get $2.50 towards a ride with a cab or ride hailing app",
	},
	"ride-hail-usd-00500": {
		type: BenefitType.rideHail,
		amount: 2.5,
		currency: Currency.USD,
		description: "get $5.00 towards a ride with a cab or ride hailing app",
	},
	"ride-hail-usd-01500": {
		type: BenefitType.rideHail,
		amount: 15,
		currency: Currency.USD,
		description: "get $15.00 towards a ride with a cab or ride hailing app",
	},
	"toll-usd-00250": {
		type: BenefitType.toll,
		amount: 2.5,
		currency: Currency.USD,
		description: "get $2.50 towards your highway toll",
	},
	"toll-usd-00500": {
		type: BenefitType.toll,
		amount: 5,
		currency: Currency.USD,
		description: "get $5.00 towards your highway toll",
	},
	"toll-usd-01500": {
		type: BenefitType.toll,
		amount: 15,
		currency: Currency.USD,
		description: "get $15.00 towards your highway toll",
	},
	"transit-usd-00300": {
		type: BenefitType.transit,
		amount: 3,
		currency: Currency.USD,
		description: "get $3.00 loaded onto your transit card",
	},
	"transit-usd-00750": {
		type: BenefitType.transit,
		amount: 7.5,
		currency: Currency.USD,
		description: "get a 1 day pass issued to your fare card",
	},
	"transit-usd-01500": {
		type: BenefitType.transit,
		amount: 15,
		currency: Currency.USD,
		description: "get a 3 day pass issued to your fare card",
	},
	"transit-usd-04500": {
		type: BenefitType.transit,
		amount: 45,
		currency: Currency.USD,
		description: "get a 1 week pass issued to your fare card",
	},
	"bike-share-cad-00250": {
		type: BenefitType.bikeShare,
		amount: 2.5,
		currency: Currency.CAD,
		description: "get $2.50 towards a bike share",
	},
	"bike-share-cad-00500": {
		type: BenefitType.bikeShare,
		amount: 5,
		currency: Currency.CAD,
		description: "get $5.00 towards a bike share",
	},
	"ferry-cad-00250": {
		type: BenefitType.ferry,
		amount: 2.5,
		currency: Currency.CAD,
		description: "get $2.50 off your your ferry fare",
	},
	"ferry-cad-00500": {
		type: BenefitType.ferry,
		amount: 5,
		currency: Currency.CAD,
		description: "get $5.00 off your your ferry fare",
	},
	"ferry-cad-01000": {
		type: BenefitType.ferry,
		amount: 10,
		currency: Currency.CAD,
		description: "get $10.00 off your your ferry fare",
	},
	"fuel-cad-00250": {
		type: BenefitType.fuel,
		amount: 2.5,
		currency: Currency.CAD,
		description: "get $2.50 in fuel at a participating gas station",
	},
	"fuel-cad-00500": {
		type: BenefitType.fuel,
		amount: 5,
		currency: Currency.CAD,
		description: "get $5.00 in fuel at a participating gas station",
	},
	"fuel-cad-05000": {
		type: BenefitType.fuel,
		amount: 50,
		currency: Currency.CAD,
		description: "get $50.00 in fuel at a participating gas station",
	},
	"parking-cad-00250": {
		type: BenefitType.parking,
		amount: 2.5,
		currency: Currency.CAD,
		description: "get $2.50 off your parking at a participating parking lot",
	},
	"parking-cad-00500": {
		type: BenefitType.parking,
		amount: 5,
		currency: Currency.CAD,
		description: "get $5 off your parking at a participating parking lot",
	},
	"parking-cad-00750": {
		type: BenefitType.parking,
		amount: 7.5,
		currency: Currency.CAD,
		description: "get $7.50 off your parking at a participating parking lot",
	},
	"parking-cad-01000": {
		type: BenefitType.parking,
		amount: 10,
		currency: Currency.CAD,
		description: "get $10 off your parking at a participating parking lot",
	},
	"parking-cad-01500": {
		type: BenefitType.parking,
		amount: 15,
		currency: Currency.CAD,
		description: "get $15 off your parking at a participating parking lot",
	},
	"parking-cad-02000": {
		type: BenefitType.parking,
		amount: 20,
		currency: Currency.CAD,
		description: "get $20 off your parking at a participating parking lot",
	},
	"parking-cad-02500": {
		type: BenefitType.parking,
		amount: 25,
		currency: Currency.CAD,
		description: "get $25 off your parking at a participating parking lot",
	},
	"parking-cad-03000": {
		type: BenefitType.parking,
		amount: 30,
		currency: Currency.CAD,
		description: "get $30 off your parking at a participating parking lot",
	},
	"parking-cad-04000": {
		type: BenefitType.parking,
		amount: 40,
		currency: Currency.CAD,
		description: "get $40 off your parking at a participating parking lot",
	},
	"parking-cad-05000": {
		type: BenefitType.parking,
		amount: 50,
		currency: Currency.CAD,
		description: "get $50 off your parking at a participating parking lot",
	},
	"points-cad-0100": {
		type: BenefitType.points,
		amount: 1,
		currency: Currency.CAD,
		description: "Get 100 points",
	},
	"points-cad-0250": {
		type: BenefitType.points,
		amount: 2.5,
		currency: Currency.CAD,
		description: "Get 250 points",
	},
	"points-cad-0500": {
		type: BenefitType.points,
		amount: 5,
		currency: Currency.CAD,
		description: "Get 500 points",
	},
	"points-cad-1000": {
		type: BenefitType.points,
		amount: 10,
		currency: Currency.CAD,
		description: "Get 1,000 points",
	},
	"ride-hail-cad-00250": {
		type: BenefitType.rideHail,
		amount: 2.5,
		currency: Currency.CAD,
		description: "get $2.50 towards a ride with a cab or ride hailing app",
	},
	"ride-hail-cad-00500": {
		type: BenefitType.rideHail,
		amount: 2.5,
		currency: Currency.CAD,
		description: "get $5.00 towards a ride with a cab or ride hailing app",
	},
	"ride-hail-cad-01500": {
		type: BenefitType.rideHail,
		amount: 15,
		currency: Currency.CAD,
		description: "get $15.00 towards a ride with a cab or ride hailing app",
	},
	"toll-cad-00250": {
		type: BenefitType.toll,
		amount: 2.5,
		currency: Currency.CAD,
		description: "get $2.50 towards your highway toll",
	},
	"toll-cad-00500": {
		type: BenefitType.toll,
		amount: 5,
		currency: Currency.CAD,
		description: "get $5.00 towards your highway toll",
	},
	"toll-cad-01500": {
		type: BenefitType.toll,
		amount: 15,
		currency: Currency.CAD,
		description: "get $15.00 towards your highway toll",
	},
	"transit-cad-00300": {
		type: BenefitType.transit,
		amount: 3,
		currency: Currency.CAD,
		description: "get $3.00 loaded onto your transit card",
	},
	"transit-cad-00750": {
		type: BenefitType.transit,
		amount: 7.5,
		currency: Currency.CAD,
		description: "get $7.50 issued to your transit card",
	},
	"transit-cad-01500": {
		type: BenefitType.transit,
		amount: 15,
		currency: Currency.CAD,
		description: "get $15 issued to your transit card",
	},
	"transit-cad-4500": {
		type: BenefitType.transit,
		amount: 45,
		currency: Currency.CAD,
		description: "get $45 issued to your transit card",
	},
};
