import {FirebaseEntity} from "./firebase-entity.model";
import {GatewayAccount} from "./gateway-account.model";
import {Summary} from "./summary.type";

export enum ProviderType {
	bikeShare = "bike-share",
	cab = "cab",
	ferry = "ferry",
	fuel = "fuel",
	parkingApp = "parking-app",
	parkingKiosk = "parking-kiosk",
	points = "points",
	toll = "toll",
	transit = "transit",
}

export interface Provider extends FirebaseEntity {
	currency: string;
	flatFee: number;
	gatewayAccounts: GatewayAccount[];
	id?: string;
	logoFilename?: string;
	logoUrl?: string;
	name: string;
	percentFee: number;
	type: ProviderType;
}

export type ProviderSummary = Summary<Provider, "name" | "type">;

function SummaryFromProvider(provider: Provider): ProviderSummary {
	return {
		key: provider.$key!, //tslint:disable-line:no-unnecessary-type-assertion
		name: provider.name,
		type: provider.type,
	};
}
