import {Component} from "@angular/core";
import {Nav} from "@ionic/angular";
import {combineLatest} from "rxjs";
import {switchMap} from "rxjs/operators";
import {Logger} from "../../../core/logger/logger.service";
import {MovebeMarkersService} from "../../../core/movebe-markers/movebe-markers.service";
import {MarkerPage} from "./marker.page";

@Component({
	selector: "page-mb-marker-list",
	styleUrls: ["./marker-list.page.scss"],
	templateUrl: "marker-list.page.html",
})
export class MarkerListPage {
	readonly markerScans$;

	constructor(
		private nav: Nav,
		private logger: Logger,
		private markersService: MovebeMarkersService
	) {
		this.markerScans$ = this.markersService
			.getCurrentUserRecentMarkerScans()
			.pipe(
				switchMap(scans =>
					combineLatest(
						scans.map(scan => this.markersService.getMarkerScan(scan.$key))
					)
				)
			);
	}

	showScan(scanId) {
		this.nav.push(MarkerPage, {scanId});
	}
}
