import {action, payload} from "ts-action";
import {
	Employer,
	MerchantRole,
} from "../../../../../lib/model/employment-relationship.model";
import {Merchant} from "../../../../../lib/model/merchant.model";

export namespace MerchantMembershipActions {
	export const BecomeMerchant = action("[MerchantMembership] Become Merchant");

	export const PresentEmailPasswordModal = action(
		"[MerchantMembership] Present Email Password Modal"
	);

	export const PresentVerifyEmailModal = action(
		"[MerchantMembership] Present Verify Email Modal"
	);

	export const PresentAcceptTermsModal = action(
		"[MerchantMembership] Present Accept Terms Modal"
	);

	export const PresentJoinOrAddModal = action(
		"[MerchantMembership] Present Join Or Add Modal"
	);

	export const AcceptTerms = action("[MerchantMembership] Accept Terms");

	export const TermsAccepted = action("[MerchantMembership] Terms Accepted");

	export const PresentEnrollNewMerchantModal = action(
		"[MerchantMembership] Enroll New Merchant"
	);

	export const JoinMerchant = action("[MerchantMembership] Join Merchant");

	export const CancelJoin = action("[MerchantMembership] Cancel Join");

	export const AddMerchant = action(
		"[MerchantMembership] Add Merchant",
		payload<{merchant: Merchant; logo: Blob}>()
	);

	export const MerchantAdded = action(
		"[MerchantMembership] Merchant Added",
		payload<{merchant: Merchant; employer: Employer}>()
	);

	export const EnrolledMerchantReceived = action(
		"[MerchantMembership] Merchant Added",
		payload<Merchant>()
	);

	export const AddCurrentUserAsEmployee = action(
		"[MerchantMembership] Add Current User As Employee",
		payload<{merchant: Merchant; role: MerchantRole}>()
	);

	export const CurrentUserAddedAsEmployee = action(
		"[MerchantMembership] Current User Added As Employee",
		payload<Employer>()
	);

	export const JoinExistingMerchantModalPresented = action(
		"[MerchantMembership] JoinExistingMerchantModalPresented"
	);

	export const EnrollMerchantModalPresented = action(
		"[MerchantMembership]EnrollMerchantModalPresented"
	);
}
