import {Component} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {BusyService} from "../../../../core/busy/busy.service";
import {Logger} from "../../../../core/logger/logger.service";
import {MovebeMarkersService} from "../../../../core/movebe-markers/movebe-markers.service";

@Component({
	selector: "page-mm-generate-signs",
	styleUrls: ["./generate-signs.page.scss"],
	templateUrl: "./generate-signs.page.html",
})
export class GenerateSignsPage {
	done = false;
	howMany: number;
	readonly maxSignsPerPdf = 100;

	constructor(
		private busyService: BusyService,
		private logger: Logger,
		private movebeMarkersService: MovebeMarkersService,
		private translateService: TranslateService
	) {}

	generateSigns() {
		this.busyService.setBusy(
			this.movebeMarkersService
				.generateSigns(this.howMany)
				.then(() => (this.done = true)),
			this.translateService.instant("MOVEBE_MARKER.GENERATING_PDF")
		);
	}

	enforceMax() {
		if (this.howMany > this.maxSignsPerPdf) {
			setTimeout(() => {
				this.howMany = this.maxSignsPerPdf;
			});
		}
	}

	reset() {
		this.done = false;
	}
}
