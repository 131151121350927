import {Voucher} from "../../app/src/core/coupon/voucher.model";
import {Validation} from "../../app/src/core/offers/validation.model";
import {Timestamp} from "../timestamp.model";
import {BenefitSummary} from "./benefit.model";
import {FirebaseEntity} from "./firebase-entity.model";
import {MerchantSummary} from "./merchant.model";
import {Offer} from "./offer.model";
import {SurveyResponse} from "./survey/survey-question-response.model";

export interface RewardBase extends FirebaseEntity {
	status: RewardStatus;
	consumerId: string;
	merchant: MerchantSummary;
	benefit: BenefitSummary;
	offerId: string;
	requiredValidations: number;
	createdTimestamp: Timestamp;
	validations: Validation[];
	surveyRequired: boolean;
}

export interface PendingReward extends RewardBase {
	status: RewardStatus.pending;
}

export interface IssuedRewardBase extends RewardBase {
	issuedTimestamp: Timestamp;
	expires: Timestamp;
	isCurrent: boolean;
	surveyCompleted: boolean;
	surveyResponse?: SurveyResponse;
	voucher?: Voucher;
}

export interface IssuedReward extends IssuedRewardBase {
	status: RewardStatus.allocated | RewardStatus.expired;
}

export interface RedeemedReward extends IssuedRewardBase {
	status: RewardStatus.redeemed;
	redeemedTimestamp: Timestamp;
}

export type Reward = PendingReward | IssuedReward | RedeemedReward;

export enum RewardStatus {
	pending = "pending",
	allocated = "allocated",
	redeemed = "redeemed",
	expired = "expired",
}

export interface RewardDetails {
	reward: Reward;
	rewardId: string;
	location: Location;
	offer: Offer;
}
