import {Component} from "@angular/core";
import {ModalController, NavParams} from "@ionic/angular";
import {Blotter} from "../../../../../lib/model/blotter.model";

@Component({
	selector: "page-mb-stakeholder-modal",
	styleUrls: ["./stakeholder.modal.scss"],
	templateUrl: "./stakeholder.modal.html",
})
export class StakeholderModal {
	stakeholderTransactions: Blotter[];
	type: string;

	constructor(private modalController: ModalController, navParams: NavParams) {
		this.stakeholderTransactions = navParams.get(
			"stakeholderTransactions"
		) as Blotter[];
		this.type = navParams.get("type") as string;
	}

	dismiss() {
		return this.modalController.dismiss();
	}
}
