import {Component, EventEmitter, Output} from "@angular/core";

@Component({
	selector: "movebe-image-upload",
	styleUrls: ["./image-upload.component.scss"],
	templateUrl: "./image-upload.component.html",
})
export class ImageUploadComponent {
	imgDataUrl: string | null;
	@Output()
	imageChanged = new EventEmitter<Blob>();

	//TODO: limit max upload size & known image file types

	async onFileChange(file: File) {
		if (!file) {
			this.imgDataUrl = null;
			return;
		}

		const reader: FileReader = new FileReader();
		reader.onload = (event: Event) => {
			this.imgDataUrl = (event.target as FileReader).result as string;
		};
		reader.readAsDataURL(file);
		this.imageChanged.emit(new Blob([file], {type: file.type}));
	}
}
