import {config} from "../../../config";
import {
	AppEnvironment,
	FirebaseConfig,
} from "../../../lib/model/config/app-environment.model";
import {AppEnvironments} from "./app-environments";

export const appEnvironment: AppEnvironment = getAppEnvironment();

export function getFirebaseConfig(): FirebaseConfig {
	return appEnvironment.firebaseConfig;
}

export function getFirebaseAppName(): string {
	return getFirebaseConfig().projectId;
}

export function getAppEnvironment(): AppEnvironment {
	return findMatchingEnvironmentFromHost() || getConfigEnvironment();
}

console.info(
	`Using project %c${appEnvironment.firebaseConfig.projectId}`,
	"color:blue"
);
console.info(`Using api url %c${appEnvironment.apiUrl}`, "color:blue");

export function findMatchingEnvironmentFromHost(): AppEnvironment | undefined {
	return Object.keys(AppEnvironments)
		.map(key => AppEnvironments[key])
		.find(env => {
			const host = window.location.host;
			const environment =
				host.startsWith(new URL(env.apiUrl).host) ||
				host === env.firebaseConfig.authDomain;
			if (environment) {
				console.info("Found environment matching URL host. Ignoring config");
			}
			return environment;
		});
}

export function getConfigEnvironment(): AppEnvironment {
	const configEnvironment = AppEnvironments[config.environment];
	return {
		...configEnvironment,
		apiUrl: config.useLocalApi
			? configEnvironment.apiUrlLocal
			: configEnvironment.apiUrl,
	};
}
