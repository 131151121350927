import {AppLink} from "../../../../lib/model/app-link.model";
import {FirebaseEntity} from "../../../../lib/model/firebase-entity.model";
import {
	ScanRequestParams,
	ScanRequestResponse,
} from "./scan-request-params.model";

export enum ScanRequestStatus {
	rejected = "rejected",
	pending = "pending",
	scanned = "scanned",
	approved = "approved",
}

export enum ScanRequestAction {
	validateOffer = "validateOffer",
	redeemReward = "redeemReward",
	joinMerchant = "joinMerchant",
}

export interface ScanRequestBase extends FirebaseEntity {
	appLink: AppLink;
	requestedByUserId: string;
	requestParams: ScanRequestParams;
	status: ScanRequestStatus;
	action: ScanRequestAction;
}

export interface PendingScanRequest extends ScanRequestBase {
	status: ScanRequestStatus.pending;
}

export interface ScannedScanRequest extends ScanRequestBase {
	status:
		| ScanRequestStatus.scanned
		| ScanRequestStatus.rejected
		| ScanRequestStatus.approved;
	scannedByUserId: string;
}

export interface ApprovedScanRequest extends ScannedScanRequest {
	status: ScanRequestStatus.approved;
	responseData: ScanRequestResponse;
}

export type ScanRequest =
	| PendingScanRequest
	| ScannedScanRequest
	| ApprovedScanRequest;
