/* tslint:disable:object-literal-key-quotes */

export let movebeMapStyles: google.maps.MapTypeStyle[] = [
	{
		elementType: "labels.text.fill",
		stylers: [
			{
				color: "#213c73",
			},
		],
	},
	{
		featureType: "administrative",
		stylers: [
			{
				visibility: "on",
			},
		],
	},
	{
		elementType: "labels.icon",
		featureType: "administrative",
		stylers: [
			{
				color: "#0000ff",
			},
		],
	},
	{
		elementType: "labels.text",
		featureType: "administrative",
		stylers: [
			{
				color: "#9ec3df",
			},
		],
	},
	{
		elementType: "labels.text.stroke",
		featureType: "administrative",
		stylers: [
			{
				color: "#ffffff",
			},
		],
	},
	{
		featureType: "landscape",
		stylers: [
			{
				visibility: "on",
			},
		],
	},
	{
		featureType: "poi",
		stylers: [
			{
				visibility: "off",
			},
		],
	},
	{
		elementType: "labels.text.fill",
		featureType: "road",
		stylers: [
			{
				color: "#213c73",
			},
		],
	},
	{
		elementType: "labels.text.stroke",
		featureType: "road",
		stylers: [
			{
				color: "#ffffff",
			},
		],
	},
	{
		elementType: "geometry.fill",
		featureType: "road.highway",
		stylers: [
			{
				color: "#cdcac1",
			},
		],
	},
	{
		elementType: "geometry.stroke",
		featureType: "road.highway",
		stylers: [
			{
				color: "#595959",
			},
		],
	},
	{
		elementType: "labels.text.fill",
		featureType: "road.highway",
		stylers: [
			{
				color: "#213c73",
			},
		],
	},
	{
		elementType: "labels.text.stroke",
		featureType: "road.highway",
		stylers: [
			{
				color: "#ffffff",
			},
		],
	},
	{
		featureType: "transit",
		stylers: [
			{
				visibility: "off",
			},
		],
	},
	{
		featureType: "transit.station",
		stylers: [
			{
				visibility: "off",
			},
		],
	},
	{
		featureType: "transit.station.airport",
		stylers: [
			{
				visibility: "on",
			},
		],
	},
];
