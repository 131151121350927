import {createFeatureSelector, createSelector} from "@ngrx/store";
import {AppEffects} from "./app.effects";
import * as fromAppStore from "./app.reducer";
import {AppState} from "./app.reducer";

export const featureName = "app";

export interface FeatureState {
	app: fromAppStore.AppState; //the name of this property must match the value of featureName const above
}

export const reducer = fromAppStore.appReducer;
export const effects = [AppEffects];

export const getAppState = createFeatureSelector<AppState>(featureName);

export const getAppMode = createSelector(getAppState, fromAppStore.getAppMode);
export const getBusyState = createSelector(
	getAppState,
	fromAppStore.getBusyState
);
