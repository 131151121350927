import {action, payload} from "ts-action";
import {MerchantLocationOffers} from "../../../core/offer-search/merchant-location-offers.model";
import {GeoSearchCriteria} from "../../geo-search/geo-search-criteria.model";

export namespace OfferSearchActions {
	export const SearchOffers = action(
		"[Offer Search] Search Offers",
		payload<GeoSearchCriteria>()
	);

	export const InitializeSearchQuery = action(
		"[Offer Search] Initialize Search Query",
		payload<GeoSearchCriteria>()
	);

	export const UpdateSearchCriteria = action(
		"[Offer Search] Update Search Criteria",
		payload<GeoSearchCriteria>()
	);

	export const SearchCriteriaReceived = action(
		"[Offer Search] Search Criteria Received",
		payload<GeoSearchCriteria>()
	);

	export const SearchResultsReceived = action(
		"[Offer Search] Search Results Received",
		payload<MerchantLocationOffers[]>()
	);

	export const ClearSearchResults = action(
		"[Offer Search] Clear Search Results"
	);
}
