import {Pipe, PipeTransform} from "@angular/core";

@Pipe({name: "movebeSentenceCase"})
export class SentenceCasePipe implements PipeTransform {
	transform(inputString: string): string {
		return inputString.replace(/^\w/, firstCharacter =>
			firstCharacter.toUpperCase()
		);
	}
}
