import {Component} from "@angular/core";
import {ModalController, NavParams} from "@ionic/angular";
import {Observable} from "rxjs/Observable";
import {Logger} from "../../core/logger/logger.service";
import {ScanRequest} from "../../core/scan-request/scan-request.model";

@Component({
	selector: "page-mm-scan-request-enlarged-modal",
	templateUrl: "./scan-request-enlarged.modal.html",
})
export class ScanRequestEnlargedModal {
	request$: Observable<ScanRequest>;
	instructionsMessage: string;

	constructor(
		private modalController: ModalController,
		private navParams: NavParams,
		private logger: Logger
	) {
		this.request$ = navParams.get("scanRequest$") as Observable<ScanRequest>;
		this.instructionsMessage = navParams.get("instructionsMessage") as string;
	}

	dismiss(success: boolean) {
		this.modalController.dismiss(success);
	}
}
