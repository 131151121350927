import {User} from "firebase/app";
import {TelephoneNumber} from "libphonenumber-js";
import {action, payload} from "ts-action";
import {Employer} from "../../../../../lib/model/employment-relationship.model";
import {Language} from "../../../../../lib/model/language.model";
import {
	Credentials,
	CredentialsWithName,
} from "../../../../../lib/model/user/credentials.model";
import {UserProfile} from "../../../../../lib/model/user/user-profile.model";
import {AppMode} from "../../../core/app-mode/app-mode.model";
import {UserState} from "./user.reducer";

export namespace UserActions {
	export const QueryAuthState = action("[User] Query AuthState");

	export const ReloadAuthState = action("[User] Reload AuthState");

	export const QueryProfile = action("[User] Query Profile");

	export const InitializeProfile = action("[User] Initialize Profile");

	export const AuthStateReceived = action(
		"[User] AuthState Received",
		payload<User>()
	);

	export const ProfileReceived = action(
		"[User] Profile Received",
		payload<UserProfile>()
	);

	export const UpdateUser = action("[User] Update", payload<UserState>());

	export const UpdateUserProfile = action(
		"[User] UpdateUserInfo",
		payload<Partial<UserProfile>>()
	);

	export const UserProfileUpdated = action("[User] UserProfile Updated");

	export const TranslationsLoaded = action("[User]  Translations Loaded");

	export const SelectLanguage = action(
		"[User] Select Language",
		payload<Language>()
	);

	export const SelectPartnerMode = action(
		"[User] Select Partner Mode",
		payload<string>()
	);

	export const SelectAppMode = action(
		"[User] Select App Mode",
		payload<AppMode>()
	);

	export const SelectCurrentMerchant = action(
		"[User] Select CurrentMerchant Mode",
		payload<Employer>()
	);

	export const ProfileInitialized = action("[User] Profile Initialized");

	export const LanguageChanged = action("[User] Language Changed");

	export const PartnerModeChanged = action("[User] Partner Mode Changed");

	export const AppModeChanged = action("[User] App Mode  Changed");

	export const CurrentMerchantChanged = action(
		"[User] Current Merchant Changed"
	);

	export const VerificationEmailSent = action("[User] Verification Email Sent");

	export const RefreshLogin = action(
		"[User] Refresh Login",
		payload<CredentialsWithName>()
	);

	export const SignInAnonymously = action("[User] Sign In Anonymously");

	export const SignInWithAppLinkAuthId = action(
		"[User] Sign In With App Link Auth Id",
		payload<string>()
	);

	export const SignInWithEmail = action(
		"[User] Sign In With Email",
		payload<Credentials>()
	);

	export const SignedIn = action("[User] Signed In", payload<User>());

	export const SignedInAnonymously = action("[User] Signed In Anonymously");

	export const SignInFailed = action("[User] Sign In Failed");

	export const SendAuthSms = action(
		"[User] Send Auth SMS",
		payload<TelephoneNumber>()
	);

	export const AuthSmsSent = action("[User] Auth SMS Sent");

	export const SignOut = action("[User] Sign Out");

	export const SignInNotified = action("[User] Sign In Notified");
}
