import {createFeatureSelector, createSelector} from "@ngrx/store";
import {OfferSearchEffects} from "./offer-search.effects";
import * as fromOfferSearchStore from "./offer-search.reducer";

export const featureName = "offerSearch";

export interface FeatureState {
	offerSearch: OfferSearchState; //the name of this property must match the value of featureName const above
}

export type OfferSearchState = fromOfferSearchStore.OfferSearchState;

export const reducer = fromOfferSearchStore.offerSearchReducer;
export const effects = [OfferSearchEffects];

export const getOfferSearchState = createFeatureSelector<OfferSearchState>(
	featureName
);

export const getSearchResults = createSelector(
	getOfferSearchState,
	fromOfferSearchStore.getSearchResults
);
