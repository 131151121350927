import {Dictionary} from "./dictionary.model";

export enum Currency {
	CAD = "CAD",
	USD = "USD",
}

export enum Country {
	CA = "CA",
	US = "US",
}

export const CurrencyName: Dictionary<string> = {
	[Currency.CAD]: "Canadian Dollar",
	[Currency.USD]: "United States Dollar",
};

export const CountryNames: Dictionary<string> = {
	[Country.CA]: "Canada",
	[Country.US]: "United States",
};

export const CountryCurrency: Dictionary<Currency> = {
	[Country.CA]: Currency.CAD,
	[Country.US]: Currency.USD,
};
