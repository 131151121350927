import {Component} from "@angular/core";
import {Store} from "@ngrx/store";
import {Observable} from "rxjs/Observable";
import * as fromUser from "../+state";
import {UserActions} from "../+state/user.actions";
import {MovebeState} from "../../../app/movebe-state.model";

@Component({
	selector: "movebe-partner-mode-chooser",
	styleUrls: ["./partner-mode-chooser.component.scss"],
	templateUrl: "./partner-mode-chooser.component.html",
})
export class PartnerModeChooserComponent {
	partnerMode$: Observable<string>;

	//TODO: import * retrieve partners from Firestore
	readonly partners = [
		{
			$key: "movebe",
			name: "MoveBe",
		},
		{
			$key: "esso-speedpass",
			name: "Esso Speedpass",
		},
		{
			$key: "yelp",
			name: "Yelp",
		},
	];

	constructor(private store: Store<MovebeState>) {
		this.partnerMode$ = this.store.select(fromUser.getPartnerMode);
	}

	partnerModeSelectionChanged(partnerMode: string) {
		this.store.dispatch(new UserActions.SelectPartnerMode(partnerMode));
	}
}
