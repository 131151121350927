import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {BenefitType} from "../../../../lib/model/benefit.model";
import {GeoSearch} from "../../lib/geo-search/geo-search";
import {GeoSearchCriteria} from "../../lib/geo-search/geo-search-criteria.model";
import {filterNulls} from "../../lib/rxjs-operators/filter-nulls";
import {FirebaseService} from "../firebase/firebase.service";
import {FirestoreService} from "../firebase/firestore.service";
import {OffersService} from "../offers/offers.service";
import {RewardSearchFilter} from "../rewards/reward-search.model";
import {RewardsService} from "../rewards/rewards.service";
import {MerchantLocationOffers} from "./merchant-location-offers.model";

@Injectable()
export class OfferSearchService {
	private geoQuery: GeoSearch<MerchantLocationOffers>;

	constructor(
		private fb: FirebaseService,
		private firestore: FirestoreService,
		private offersService: OffersService,
		private rewardsService: RewardsService
	) {}

	initializeOfferSearch(
		queryCriteria: GeoSearchCriteria
	): Observable<MerchantLocationOffers[]> {
		this.geoQuery = this.fb.searchMerchantLocations(
			{radius: 0, center: [0, 0]},
			item => {
				const [merchantId, locationId] = item.key.split("|");
				return this.getMerchantLocationOffers(merchantId, locationId);
			}
		);
		this.geoQuery.updateQueryCriteria(queryCriteria);
		return this.geoQuery.results$;
	}

	updateSearchCriteria(newQueryCriteria: GeoSearchCriteria): any {
		if (!this.geoQuery) {
			throw new Error(
				"Must initialize offer search before updating search criteria "
			);
		}
		return this.geoQuery.updateQueryCriteria(newQueryCriteria);
	}

	private getMerchantLocationOffers(
		merchantId: string,
		locationId: string
	): Observable<MerchantLocationOffers> {
		return Observable.combineLatest(
			this.firestore
				.toObjectStream(
					this.firestore.getMerchantLocation(merchantId, locationId)
				)
				.pipe(filterNulls()),
			this.firestore
				.toObjectStream(this.firestore.getMerchant(merchantId))
				.pipe(filterNulls()),
			this.offersService.getVisibleOffers(merchantId),
			this.rewardsService.getCurrentUserRewards({
				benefitType: BenefitType.coupon,
				filter: RewardSearchFilter.current,
				merchantId,
			})
		).map(([location, merchant, offers, rewards]) => ({
			location,
			merchant,
			offers,
			rewards,
		}));
	}
}
