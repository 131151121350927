// tslint:disable:no-magic-numbers
// tslint:disable:member-ordering
// tslint:disable:no-console
import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {from, Observable} from "rxjs";
import {
	BenefitType,
	ProviderBenefit,
	SummaryFromBenefit,
} from "../../../../lib/model/benefit.model";
import {Dictionary} from "../../../../lib/model/dictionary.model";
import {EmploymentRelationship} from "../../../../lib/model/employment-relationship.model";
import {Gateway} from "../../../../lib/model/gateway.model";
import {Merchant} from "../../../../lib/model/merchant.model";
import {Offer} from "../../../../lib/model/offer.model";
import {Provider} from "../../../../lib/model/provider.model";
import {Survey} from "../../../../lib/model/survey/survey.model";
import {
	SummaryFromUserProfile,
	UserProfile,
} from "../../../../lib/model/user/user-profile.model";
import {appEnvironment} from "../../app/app-environment";
import {MovebeState} from "../../app/movebe-state.model";
import {benefitData} from "../../assets/data/benefits-data";
import {gatewaysData} from "../../assets/data/gateways-data";
import {merchantData, MerchantInfo} from "../../assets/data/merchant-data";
import {permitTagData} from "../../assets/data/permit-tag-data";
import {providersData} from "../../assets/data/providers-data";
import {settingsData} from "../../assets/data/settings-data";
import {surveysData} from "../../assets/data/surveys-data";
import {filterNulls} from "../../lib/rxjs-operators/filter-nulls";
import * as fromUser from "../../lib/user/+state";
import {FirebaseService} from "../firebase/firebase.service";
import {FirestoreService} from "../firebase/firestore.service";
import {Logger} from "../logger/logger.service";
import {MappingService} from "../mapping/mapping.service";
import {
	MerchantsService,
	torontoLatitude,
	torontoLongitude,
} from "../merchants/merchants.service";
import {MovebeApiService} from "../movebe-api/movebe-api.service";
import {OffersService} from "../offers/offers.service";
import {SettingsService} from "../settings/settings.service";

@Injectable()
export class DataImporterService {
	private readonly toronto = new google.maps.LatLng(
		torontoLatitude,
		torontoLongitude
	);
	private readonly currentLocation = this.toronto;

	private readonly userId$: Observable<string>;
	private readonly userProfile$: Observable<UserProfile>;

	constructor(
		private fb: FirebaseService,
		private firestore: FirestoreService,
		private http: HttpClient,
		private logger: Logger,
		private mappingService: MappingService,
		private merchantsService: MerchantsService,
		private movebeApiService: MovebeApiService,
		private offerService: OffersService,
		private settingsService: SettingsService,
		private store: Store<MovebeState>
	) {
		this.userId$ = this.store.select(fromUser.getUserId).pipe(filterNulls());
		this.userProfile$ = this.store.select(fromUser.getUserProfile);
	}

	readonly locationsPerMerchant = 4;

	importData() {
		return this.cleanEnvironment()
			.then(() => this.importBenefits())
			.then(() => this.importProviders())
			.then(() => this.importPermitTags())
			.then(() => this.importMerchantData());
	}

	cleanEnvironment(): Promise<any> {
		return this.userId$
			.first()
			.toPromise()
			.then(userId => {
				return Promise.all([
					this.fb.cleanEnv(userId),
					this.firestore.cleanEnv(userId),
					this.movebeApiService.cleanEnvironment(),
				]);
			});
	}

	deleteTransactions(): Promise<any> {
		return this.movebeApiService.deleteTransactions();
	}

	importBenefits(): Promise<any> {
		console.groupCollapsed("Importing Benefits");
		return Promise.all(
			Object.keys(benefitData).map((benefitId: string) => {
				const benefit: ProviderBenefit = benefitData[benefitId];
				return this.offerService
					.addBenefit(benefit, benefitId)
					.first()
					.toPromise()
					.then(() => console.info(benefit));
			})
		).then(() => {
			console.groupEnd();
			console.log("Done Importing Benefits");
		});
	}

	importGateways(): Promise<any> {
		return Promise.all(
			gatewaysData.map((gateway: Gateway) => {
				return this.movebeApiService
					.putGateway(gateway)
					.first()
					.toPromise();
			})
		);
	}

	importProviders(): Promise<any> {
		console.groupCollapsed("Importing Providers");
		return Promise.all(
			providersData.map((provider: Provider) => {
				provider.id = this.merchantsService.merchantSlug(provider.name);
				return this.movebeApiService
					.putProvider(provider)
					.first()
					.toPromise()
					.then(() => {
						//tslint:disable-line:no-unsafe-any
						this.http
							.get(`assets/img/providers/${provider.id}.png`, {
								responseType: "blob",
							})
							.subscribe(
								res => {
									const img = new Blob([res], {type: "image/png"});
									this.firestore.uploadProviderLogo(provider, img);
									return {};
								},
								error => {
									this.logger.error(error);
								}
							);
					});
			})
		).then(() => {
			console.groupEnd();
			console.log("Done Importing Providers");
		});
	}

	importPermitTags(): Promise<any> {
		console.groupCollapsed("Importing Permit Tags");
		return Promise.all(
			permitTagData.map(permitTag =>
				this.fb
					.addPermitTag("loco-mobi", permitTag)
					.then(() => console.info(permitTag))
			)
		).then(() => {
			console.groupEnd();
			console.log("Done Importing Permit Tags");
		});
	}

	importMerchantData() {
		return this.userId$.first().subscribe(userId => {
			return Observable.from(this.clone(merchantData))
				.flatMap((merchantInfo: MerchantInfo) => {
					const merchantProfile: Merchant = merchantInfo.profile;
					if (appEnvironment.useMerchantSearchName) {
						merchantProfile.name = merchantInfo.searchName;
					}
					merchantProfile.createdBy = userId;
					this.logger.info(`adding merchant ${merchantInfo.profile.name}`);
					return this.merchantsService
						.addMerchant(merchantProfile)
						.catch(error => {
							this.logger.error(error);
							return Observable.empty();
						})
						.pipe(filterNulls())
						.first()
						.do((merchant: Merchant) => {
							this.logger.info(`added  ${merchant.name}`);
							this.uploadMerchantLogo(merchant);
						})
						.flatMap((merchant: Merchant) => {
							const couponIds = Object.keys(merchantInfo.coupons);
							return (couponIds.length
								? Observable.forkJoin(
										couponIds.map(couponId => {
											const coupon = merchantInfo.coupons[couponId];
											return this.offerService
												.addCoupon(merchant.id!, coupon)
												.then(newCouponId => [couponId, newCouponId]);
										})
								  )
								: Observable.of([])
							).map(couponIdMap => {
								return {
									couponIds: couponIdMap.reduce<Dictionary<string>>(
										(objectIds, [oldCouponId, newCouponId]) => ({
											...objectIds,
											[oldCouponId]: newCouponId,
										}),
										{}
									),
									merchant,
								};
							});
						})
						.map(({merchant, couponIds}) => {
							if (merchantInfo.offers) {
								merchantInfo.offers.forEach((offer: Offer) => {
									if (offer.benefit.type === BenefitType.coupon) {
										const thisCoupon = merchantInfo.coupons[offer.benefit.key];
										if (!thisCoupon) {
											throw new Error(`missing coupon ${offer.benefit.key}`);
										}
										offer.benefit = {
											...SummaryFromBenefit(thisCoupon),
											key: couponIds[offer.benefit.key],
										};
									} else {
										const thisBenefit = benefitData[offer.benefit.key];
										if (!thisBenefit) {
											throw new Error(`missing benefit ${offer.benefit.key}`);
										}
										offer.benefit = {
											...SummaryFromBenefit(thisBenefit),
											key: offer.benefit.key,
										};
									}
									this.offerService.addOffer(merchant.id!, offer);
								});
							}
							return this.getMerchantLocations(
								merchantInfo.searchName || merchantProfile.name
							)
								.flatMap(places => places)
								.map(place => ({
									merchant,
									place,
								}))
								.take(this.locationsPerMerchant);
						});
				})
				.concatAll()
				.flatMap(({merchant, place}) =>
					this.merchantsService.addLocationFromGoogleMapData(merchant, place)
				)
				.subscribe(
					locationId => console.log("added location", locationId),
					error => {
						this.logger.error(error);
						return Observable.empty();
					}
				);
		});
	}

	importSettings() {
		return this.settingsService.saveSettings(settingsData);
	}

	importSurveys(): Promise<any> {
		return Promise.all(
			surveysData.map((survey: Survey) =>
				this.movebeApiService
					.putSurvey(survey)
					.first()
					.toPromise()
			)
		);
	}

	private getMerchantLocations(merchantName: string) {
		this.logger.info(`getting places for ${merchantName}`);
		return from(
			this.mappingService.getPlaces(merchantName, this.currentLocation, 5000)
		);
	}

	private uploadMerchantLogo(merchant: Merchant) {
		const slug = this.merchantsService.merchantSlug(merchant.name);
		return this.http
			.get(`assets/img/logos/${slug}.png`, {
				responseType: "blob",
			})
			.subscribe(
				res => {
					const img = new Blob([res], {type: "image/png"});
					this.merchantsService.uploadLogo(merchant, img);
					return {};
				},
				error => {
					this.logger.error(error);
				}
			);
	}

	addMerchantUser(
		merchant: Merchant,
		employmentRelationship: EmploymentRelationship
	): Promise<any> {
		return this.userProfile$
			.first()
			.map(userProfile => SummaryFromUserProfile(userProfile))
			.toPromise()
			.then(userSummary =>
				this.merchantsService.setMerchantUserEmployment(
					merchant,
					userSummary,
					employmentRelationship
				)
			);
	}

	private clone<T>(dataObject: T): T {
		return JSON.parse(JSON.stringify(dataObject)) as T;
	}
}
