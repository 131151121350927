import {GatewayAccount} from "../../../../lib/model/gateway-account.model";
import {Provider, ProviderType} from "../../../../lib/model/provider.model";

const gatewayAccounts: GatewayAccount[] = [
	{gatewayId: "cash"},
	{gatewayId: "cheque"},
	{gatewayId: "eft"},
	{gatewayId: "other"},
	{gatewayId: "paypal", number: "12345678"},
];

export const providersData: Partial<Provider>[] = [
	{
		currency: "CAD",
		flatFee: 0.45,
		gatewayAccounts,
		name: "App Park",
		percentFee: 3.5,
		type: ProviderType.parkingApp,
	},
	{
		currency: "CAD",
		flatFee: 0.45,
		gatewayAccounts,
		name: "City Bike",
		percentFee: 3.5,
		type: ProviderType.bikeShare,
	},
	{
		currency: "CAD",
		flatFee: 0.45,
		gatewayAccounts,
		name: "Coast Connections",
		percentFee: 3.5,
		type: ProviderType.ferry,
	},
	{
		currency: "CAD",
		flatFee: 0.45,
		gatewayAccounts,
		name: "Future Fuel",
		percentFee: 3.5,
		type: ProviderType.fuel,
	},
	{
		currency: "CAD",
		flatFee: 0.45,
		gatewayAccounts,
		name: "Lot Buddy",
		percentFee: 3.5,
		type: ProviderType.parkingApp,
	},
	{
		currency: "CAD",
		flatFee: 0.45,
		gatewayAccounts,
		name: "MyPoints",
		percentFee: 3.5,
		type: ProviderType.points,
	},
	{
		currency: "CAD",
		flatFee: 0.45,
		gatewayAccounts,
		name: "Parkmate",
		percentFee: 3.5,
		type: ProviderType.parkingApp,
	},
	{
		currency: "CAD",
		flatFee: 0.45,
		gatewayAccounts,
		name: "Paygo",
		percentFee: 3.5,
		type: ProviderType.parkingApp,
	},
	{
		currency: "CAD",
		flatFee: 0.45,
		gatewayAccounts,
		name: "Payment Kiosk",
		percentFee: 3.5,
		type: ProviderType.parkingKiosk,
	},
	{
		currency: "CAD",
		flatFee: 0.45,
		gatewayAccounts,
		name: "Taxi Call",
		percentFee: 3.5,
		type: ProviderType.cab,
	},
	{
		currency: "CAD",
		flatFee: 0.45,
		gatewayAccounts,
		name: "Toll Pass",
		percentFee: 3.5,
		type: ProviderType.toll,
	},
	{
		currency: "CAD",
		flatFee: 0.45,
		gatewayAccounts,
		name: "Transit Pass",
		percentFee: 3.5,
		type: ProviderType.transit,
	},
	{
		currency: "CAD",
		flatFee: 0.45,
		gatewayAccounts,
		name: "Zip Fuel",
		percentFee: 3.5,
		type: ProviderType.fuel,
	},
	{
		currency: "CAD",
		flatFee: 0.45,
		gatewayAccounts,
		name: "Zoom Toll",
		percentFee: 3.5,
		type: ProviderType.toll,
	},
];
