import {Component, ElementRef, EventEmitter, Output} from "@angular/core";
import {ResizedEvent} from "angular-resize-event/resized-event";

@Component({
	selector: "movebe-scroll-list-item",
	styleUrls: ["scroll-list-item.component.scss"],
	templateUrl: "scroll-list-item.component.html",
})
export class ScrollListItemComponent {
	nativeElement: HTMLElement;

	@Output()
	readonly resized = new EventEmitter<ResizedEvent>();

	constructor(elementRef: ElementRef) {
		this.nativeElement = elementRef.nativeElement as HTMLElement;
	}
}
