import {GeoFire, GeoQuery} from "geofire";
import {
	combineLatest as observableCombineLatest,
	from as observableFrom,
	Observable,
	of,
	Subject,
} from "rxjs";
import {switchMap} from "rxjs/operators";
import {GeoSearchCriteria} from "./geo-search-criteria.model";
import {GeoSearchMapFunction} from "./geo-search-map-function.model";
import {GeoSearchResult} from "./geo-search-result.model";

export class GeoSearch<T> {
	private readonly query: GeoQuery;
	private readonly resultItems: {
		geoQueryResult: GeoSearchResult;
		mappedResult$: Observable<T>;
	}[] = [];

	private readonly mappedResult$s$ = new Subject<Observable<T>[]>();
	private readonly mapFunction: (item: GeoSearchResult) => Observable<any>;

	private ready = false;
	readonly results$: Observable<T[]>;

	constructor(
		private ref,
		private queryCriteria: GeoSearchCriteria,
		mapFunction?: GeoSearchMapFunction<T>
	) {
		this.mapFunction = mapFunction || GeoSearch.defaultmapFunction;
		//tslint:disable:no-unsafe-any
		this.results$ = this.mappedResult$s$.pipe(
			switchMap((mappedResult$s: Observable<T>[]) => {
				return mappedResult$s.length
					? observableCombineLatest(mappedResult$s)
					: of([]);
			})
		);
		this.query = new GeoFire(this.ref).query(this.queryCriteria);

		this.query.on("ready", () => this.onQueryReady());
		this.query.on("key_entered", (key, location, distance) =>
			this.onQueryKeyEntered(key, location, distance)
		);
		this.query.on("key_exited", (key, location, distance) =>
			this.onQueryKeyExited(key, location, distance)
		);
		this.query.on("key_moved", (key, location, distance) =>
			this.onQueryKeyMoved(key, location, distance)
		);
	}

	radius() {
		return this.query.radius();
	}

	updateQueryCriteria(newQueryCriteria: GeoSearchCriteria) {
		this.ready = false;
		this.query.updateCriteria(newQueryCriteria);
	}

	private static defaultmapFunction(item) {
		return observableFrom([item]);
	}

	private onQueryReady() {
		this.ready = true;
		this.emitResults();
	}

	private onQueryKeyEntered(key, location, distance) {
		const item = {key, location, distance};
		this.resultItems.push({
			geoQueryResult: item,
			mappedResult$: this.mapFunction(item),
		});
		// this.geoQueryResults.push(item);
		// this.mappedResults.push(this.mapFunction(item));
		this.emitResults();
	}

	private onQueryKeyExited(key, location, distance) {
		const index = this.indexOfKey(key);
		if (index > -1) {
			this.resultItems.splice(index, 1);
		}
		this.emitResults();
	}

	private onQueryKeyMoved(key, location, distance) {
		const index = this.indexOfKey(key);
		if (index > -1) {
			const item = {key, location, distance};
			this.resultItems[index] = {
				geoQueryResult: item,
				mappedResult$: this.mapFunction(item),
			};
		}
		this.emitResults();
	}

	private indexOfKey(key) {
		return this.resultItems.findIndex(item => item.geoQueryResult.key === key);
	}

	private emitResults() {
		if (this.ready) {
			this.mappedResult$s$.next(
				this.resultItems
					.sort((a, b) => a.geoQueryResult.distance - b.geoQueryResult.distance)
					.map(item => item.mappedResult$)
			);
		}
	}
}
