import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {Settings} from "../../../../lib/model/settings.model";
import {FirestoreService} from "../firebase/firestore.service";

@Injectable()
export class SettingsService {
	constructor(private firestore: FirestoreService) {}

	getSettings(): Observable<Settings | null> {
		return this.firestore.toObjectStream<Settings>(
			this.firestore.getSettings()
		);
	}

	saveSettings(settings: Settings) {
		return this.firestore.getSettings().set(settings);
	}
}
