import {Duration} from "../../app/src/core/misc/duration.model";
import {BenefitSummary} from "./benefit.model";
import {FirebaseEntity} from "./firebase-entity.model";
import {Summary} from "./summary.type";

export interface Offer extends FirebaseEntity {
	beginDate?: string;
	benefit: BenefitSummary;
	criteria: string;
	endDate?: string;
	expires: Duration;
	location?: string;
	sortPosition: number;
	status: OfferStatus;
	requiredValidations: number;
	universal: boolean;
}

export enum OfferStatus {
	new = "new",
	activated = "activated",
	deactivated = "deactivated",
	archived = "archived",
}

export type OfferSummary = Summary<Offer, "criteria" | "benefit">;

export function SummaryFromOffer(offer: Offer): OfferSummary {
	return {
		benefit: offer.benefit,
		criteria: offer.criteria,
		key: offer.$key!, //tslint:disable-line:no-unnecessary-type-assertion
	};
}
