import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {Observable} from "rxjs/Observable";
import {LatLngLiteral} from "../../../core/mapping/lat-lng-literal";
import {MappingService} from "../../../core/mapping/mapping.service";
import {MapMarkerState, MapMarkerType} from "../map-marker-type.model";
import {MapMarker} from "../map-marker.class";
import {movebeMapStyles} from "../map-styles.data";

@Component({
	selector: "movebe-map-js",
	styleUrls: ["map-js.component.scss"],
	templateUrl: "map-js.component.html",
})
export class MapJsComponent implements OnInit {
	@Input()
	highlightedItem$: Observable<{itemNumber: number; itemType: MapMarkerType}>;

	@Input()
	mapCenter: LatLngLiteral;

	@Input()
	offerMarkers$: Observable<MapMarker[]>;

	@Input()
	parkingMarkers$: Observable<MapMarker[]>;

	@Output()
	readonly boundsChanged = new EventEmitter<google.maps.LatLngBounds>();

	@Output()
	markerClicked = new EventEmitter<{
		itemNumber: number;
		itemType: MapMarkerType;
	}>();

	highlightMarker$: Observable<MapMarker[]>;
	mapOptions: google.maps.MapOptions;

	constructor(private mappingService: MappingService) {}

	ngOnInit() {
		this.mapOptions = {
			center: {lat: this.mapCenter.lat, lng: this.mapCenter.lng},
			disableDefaultUI: true,
			styles: movebeMapStyles,
			zoom: 14,
		};

		this.highlightMarker$ = Observable.combineLatest(
			this.offerMarkers$,
			this.parkingMarkers$,
			this.highlightedItem$,
			(offerMarkers, parkingMarkers, highlightedItem) => {
				return (highlightedItem.itemType === MapMarkerType.parking
					? parkingMarkers
					: offerMarkers
				)
					.slice(highlightedItem.itemNumber, highlightedItem.itemNumber + 1)
					.map(
						marker =>
							new MapMarker(
								marker.position,
								marker.markerType,
								MapMarkerState.highlight
							)
					);
			}
		);
	}

	offerMarkerClick(itemNumber: number) {
		this.markerClicked.emit({itemNumber, itemType: MapMarkerType.merchant});
	}

	parkingMarkerClick(itemNumber: number) {
		this.markerClicked.emit({itemNumber, itemType: MapMarkerType.parking});
	}
}
