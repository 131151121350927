import {LatLngLiteral} from "../../core/mapping/lat-lng-literal";
import {
	MapMarkerIcon,
	MapMarkerState,
	MapMarkerType,
} from "./map-marker-type.model";
import LatLng = google.maps.LatLng;

export class MapMarker {
	readonly mapMarkerSizes = {
		[MapMarkerType.parking]: 20,
		[MapMarkerType.position]: 20,
		[MapMarkerType.voucher]: 20,
		[MapMarkerType.merchant]: 15,
	};
	readonly icon: MapMarkerIcon;

	constructor(
		readonly position: LatLngLiteral | LatLng,
		readonly markerType: MapMarkerType,
		readonly markerState: MapMarkerState = MapMarkerState.normal
	) {
		const size = this.mapMarkerSizes[this.markerType];
		const middle = size / 2; //tslint:disable-line:no-magic-numbers
		this.icon = {
			anchor: [middle, middle],
			size: [size, size],
			url: `assets/img/map-markers/${this.markerType}-marker-${
				this.markerState
			}.png`,
		};
	}
}
