import {Injectable} from "@angular/core";
import {Actions, Effect} from "@ngrx/effects";
import {Action, select, Store} from "@ngrx/store";
import {Observable} from "rxjs/Observable";
import {map, switchMap} from "rxjs/operators";
import {ofType} from "ts-action-operators";
import {Employer} from "../../../../../../lib/model/employment-relationship.model";
import {MovebeState} from "../../../../app/movebe-state.model";
import {FirebaseService} from "../../../../core/firebase/firebase.service";
import {FirestoreService} from "../../../../core/firebase/firestore.service";
import {filterNulls} from "../../../../lib/rxjs-operators/filter-nulls";
import * as fromUser from "../../../../lib/user/+state";
import {CurrentUserActions} from "./current-user.actions";

@Injectable()
export class CurrentUserEffects {
	readonly currentUserId$: Observable<string>;

	@Effect()
	readonly queryEmployersEffect$: Observable<Action>;

	constructor(
		private actions$: Actions,
		private fb: FirebaseService,
		private firestore: FirestoreService,
		private store: Store<MovebeState>
	) {
		this.currentUserId$ = store
			.pipe(select(fromUser.getUserId))
			.pipe(filterNulls());

		this.queryEmployersEffect$ = this.actions$.pipe(
			ofType(CurrentUserActions.QueryEmployers),
			switchMap(() => this.currentUserId$),
			switchMap(userId => this.getUserEmployers(userId)),
			map(merchants => new CurrentUserActions.EmployersReceived(merchants))
		);
	}

	getUserEmployers(userId: string): Observable<Employer[]> {
		return this.firestore.toListStream(this.firestore.getUserEmployers(userId));
	}
}
