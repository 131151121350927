import {Injectable} from "@angular/core";
import * as Sentry from "@sentry/browser";

@Injectable()
export class Logger {
	error(error: any) {
		console.error(error);
		Sentry.captureException(error); //tslint:disable-line:no-unsafe-any
	}

	info(message?: any, ...optionalParams: any[]) {
		console.info(message, optionalParams);
	}

	log(message?: any, ...optionalParams: any[]) {
		console.log(message, optionalParams); //tslint:disable-line:no-console
	}
}
