/*tslint:disable:no-unsafe-any*/
import {Pipe, PipeTransform} from "@angular/core";

/**
 * Converts an object into an array of key value pairs.
 * Allows iteration over keys of an object with *ngFor
 */
@Pipe({name: "movebeObjectByKey"})
export class ObjectByKeyPipe implements PipeTransform {
	transform(object, args: string[]): {key: string; value: any}[] {
		return Object.keys(object || {}).map(key => {
			return {
				key,
				value: object[key],
			};
		});
	}
}
