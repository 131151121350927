import {Component, ViewChild} from "@angular/core";
import {ModalController} from "@ionic/angular";
import {Logger} from "../../../core/logger/logger.service";
import {ScanComponent} from "../../../shared/scanning/scan.component";

@Component({
	selector: "page-mb-scan",
	templateUrl: "scan.modal.html",
})
export class ScanModal {
	@ViewChild(ScanComponent)
	scanComponent: ScanComponent;

	constructor(private modalCtrl: ModalController, private logger: Logger) {}

	ionViewDidEnter() {
		this.scanComponent.scan();
	}

	dismiss() {
		this.modalCtrl.dismiss();
	}
}
