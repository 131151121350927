/**
 * code taken from https://github.com/andrucz/ionic2-rating
 * waiting for a new release that is compatible with ionic 4.
 * see: https://github.com/andrucz/ionic2-rating/issues/44
 * TODO: replace with npm package if/when ionic 4 compatible version is released
 */
/* tslint:disable */

import {Component, forwardRef, Input, OnInit} from "@angular/core";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

const noop = () => {};

export const RATING_CONTROL_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => StarRatingComponent),
	multi: true,
};

@Component({
	selector: "movebe-star-rating",
	styleUrls: ["./star-rating.component.scss"],
	templateUrl: "./star-rating.component.html",
	providers: [RATING_CONTROL_VALUE_ACCESSOR],
})
export class StarRatingComponent implements OnInit, ControlValueAccessor {
	_max = 5;
	_readOnly = false;
	_emptyStarIconName = "star-outline";
	_halfStarIconName = "star-half";
	_starIconName = "star";
	_nullable = false;

	@Input()
	get max() {
		return this._max;
	}

	set max(val: any) {
		const newValue = this.getNumberPropertyValue(val);
		if (newValue !== this._max) {
			this._max = newValue;
			this.createStarIndexes();
		}
	}

	@Input()
	get readOnly() {
		return this._readOnly;
	}

	set readOnly(val: any) {
		this._readOnly = this.isTrueProperty(val);
	}

	@Input()
	get emptyStarIconName() {
		return this._emptyStarIconName;
	}

	set emptyStarIconName(val: any) {
		this._emptyStarIconName = val;
	}

	@Input()
	get halfStarIconName() {
		return this._halfStarIconName;
	}

	set halfStarIconName(val: any) {
		this._halfStarIconName = val;
	}

	@Input()
	get starIconName() {
		return this._starIconName;
	}

	set starIconName(val: any) {
		this._starIconName = val;
	}

	@Input()
	get nullable() {
		return this._nullable;
	}

	set nullable(val: any) {
		this._nullable = this.isTrueProperty(val);
	}

	innerValue: any;
	starIndexes: Array<number>;

	onChangeCallback: (_: any) => void = noop;

	ngOnInit() {
		// ngFor needs an array
		this.createStarIndexes();
	}

	createStarIndexes() {
		this.starIndexes = Array(this.max)
			.fill(1)
			.map((x, i) => i);
	}

	getStarIconName(starIndex: number) {
		if (this.value === undefined) {
			return this.emptyStarIconName;
		}

		if (this.value > starIndex) {
			if (this.value < starIndex + 1) {
				return this.halfStarIconName;
			} else {
				return this.starIconName;
			}
		} else {
			return this.emptyStarIconName;
		}
	}

	get value(): any {
		return this.innerValue;
	}

	set value(value: any) {
		if (value !== this.innerValue) {
			this.innerValue = value;
			this.onChangeCallback(value);
		}
	}

	writeValue(value: any) {
		if (value !== this.innerValue) {
			this.innerValue = value;
		}
	}

	registerOnChange(fn: any) {
		this.onChangeCallback = fn;
	}

	registerOnTouched(fn: any) {}

	onKeyDown(event: any) {
		if (/(37|38|39|40)/.test(event.which)) {
			event.preventDefault();
			event.stopPropagation();

			const newValue =
				this.value + (event.which === 38 || event.which === 39 ? 1 : -1);
			return this.rate(newValue);
		}
	}

	rate(value: number) {
		if (this.readOnly || value < 0 || value > this.max) {
			return;
		}

		if (value === this.value && this.nullable) {
			value = null;
		}

		this.value = value;
	}

	private isTrueProperty(val: any): boolean {
		if (typeof val === "string") {
			val = val.toLowerCase().trim();
			return val === "true" || val === "on";
		}
		return !!val;
	}

	private getNumberPropertyValue(val: any): number {
		if (typeof val === "string") {
			return parseInt(val.trim(), 10);
		}
		return val;
	}
}
