import {Component} from "@angular/core";
import {AppVersion} from "@ionic-native/app-version/ngx";
import {Network} from "@ionic-native/network/ngx";
import {Platform} from "@ionic/angular";
import {Logger} from "../../core/logger/logger.service";
import {appEnvironment} from "../app-environment";

@Component({
	selector: "movebe-app-info",
	templateUrl: "./app-info.page.html",
})
export class AppInfoPage {
	applicationName = "N/A";
	environment = appEnvironment.firebaseConfig.authDomain;
	modal: any;
	networkCheckDelay = 1500;
	networkType = "N/A";
	packageName = "N/A";
	versionCode = "N/A";
	versionNumber = "N/A";

	constructor(
		private appVersion: AppVersion,
		private platform: Platform,
		private logger: Logger,
		private network: Network
	) {
		if (this.platform.is("cordova")) {
			this.getAppVersionInfo();
		}
	}

	async getAppVersionInfo() {
		setTimeout(() => {
			this.networkType = this.network.type;
		}, this.networkCheckDelay);

		const appVersion = this.appVersion;
		this.applicationName = await appVersion.getAppName();
		this.packageName = await appVersion.getPackageName();
		this.versionCode = (await appVersion.getVersionCode()).toString();
		this.versionNumber = await appVersion.getVersionNumber();
	}
}
