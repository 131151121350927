import {ChangeDetectorRef, Component, ViewChild} from "@angular/core";
import {Validators} from "@angular/forms";
import {Content, Nav, NavParams} from "@ionic/angular";
import {select, Store} from "@ngrx/store";
import {
	AbstractControl,
	FormBuilder,
	FormGroup,
} from "ngx-strongly-typed-forms";
import {combineLatest} from "rxjs";
import {Observable} from "rxjs/Observable";
import {
	delay,
	distinctUntilChanged,
	first,
	map,
	shareReplay,
	switchMap,
} from "rxjs/operators";
import * as fromMerchant from "../+state";
import {
	Benefit,
	BenefitSummary,
	BenefitType,
	Coupon,
	SummaryFromBenefit,
} from "../../../../../lib/model/benefit.model";
import {Dictionary} from "../../../../../lib/model/dictionary.model";
import {Language} from "../../../../../lib/model/language.model";
import {MerchantLocation} from "../../../../../lib/model/merchant-location.model";
import {Offer, OfferStatus} from "../../../../../lib/model/offer.model";
import {groupBy, indexBy} from "../../../../../lib/util/array-map";
import {MovebeState} from "../../../app/movebe-state.model";
import {BusyService} from "../../../core/busy/busy.service";
import {Logger} from "../../../core/logger/logger.service";
import {TimeUnitTypes} from "../../../core/misc/time-slice.model";
import {BenefitTypes} from "../../../core/offers/benefit-types";
import {OffersService} from "../../../core/offers/offers.service";
import {filterNulls} from "../../../lib/rxjs-operators/filter-nulls";
import * as fromUser from "../../../lib/user/+state";
import {OfferForm} from "./offer-form.model";

@Component({
	selector: "page-mm-offer",
	styleUrls: ["./offer.page.scss"],
	templateUrl: "./offer.page.html",
})
export class OfferPage {
	@ViewChild(Content)
	content: Content;
	readonly benefits$: Observable<Benefit[]>;
	readonly availableBenefits$: Observable<Benefit[] | Coupon[]>;
	readonly benefitTypes = BenefitTypes;
	readonly currentMerchantId$: Observable<string>;
	readonly coupons$: Observable<Coupon[]>;
	readonly durationTypes = Object.keys(TimeUnitTypes);
	language$: Observable<Language>;
	locations$: Observable<MerchantLocation[]>;
	readonly productTypes = Object.keys(BenefitTypes);
	readonly timeUnitTypes = TimeUnitTypes;
	BenefitType = BenefitType;
	originalOffer$: Observable<Offer>;
	resultOffer$: Observable<Offer>;
	readonly offerForm: FormGroup<OfferForm>;
	readonly resizeDebounceTime = 250;
	disabled = true;
	OfferStatus = OfferStatus;

	get benefitIdControl(): AbstractControl<string> {
		return this.offerForm.get("benefitId")!;
	}

	get benefitTypeControl(): AbstractControl<string> {
		return this.offerForm.get("benefitType")!;
	}

	get criteriaControl(): AbstractControl<string> {
		return this.offerForm.get("criteria")!;
	}

	get expiresQuantityControl(): AbstractControl<string> {
		return this.offerForm.get("expiresQuantity")!;
	}

	get expiresUnitControl(): AbstractControl<string> {
		return this.offerForm.get("expiresUnit")!;
	}

	get locationControl() {
		return this.offerForm.get("location")!;
	}

	constructor(
		private busyService: BusyService,
		private changeDetector: ChangeDetectorRef,
		private nav: Nav,
		private offersService: OffersService,
		private logger: Logger,
		private navParams: NavParams,
		private store: Store<MovebeState>,
		private formBuilder: FormBuilder
	) {
		const offerId: string = this.navParams.get("offerId") as string;

		this.offerForm = formBuilder.group<OfferForm>({
			benefitId: ["", Validators.required],
			benefitType: [BenefitType.parking, Validators.required],
			criteria: ["", Validators.required],
			expiresQuantity: ["", Validators.required],
			expiresUnit: ["", Validators.required],
			location: [""],
		});

		this.language$ = this.store.pipe(select(fromUser.getLanguage));

		this.currentMerchantId$ = this.store.pipe(
			select(fromMerchant.getCurrentMerchantId),
			filterNulls()
		);

		this.coupons$ = this.currentMerchantId$.switchMap(merchantId =>
			this.offersService.getCoupons(merchantId)
		);

		this.locations$ = this.store.pipe(
			select(fromMerchant.getMerchantLocations)
		);

		this.benefits$ = this.store.pipe(
			select(fromMerchant.getCurrentMerchant),
			filterNulls(),
			switchMap(merchant => this.offersService.getBenefits(merchant.currency))
		);

		const benefitsDictionary$: Observable<
			Dictionary<Benefit | Coupon>
		> = Observable.combineLatest(this.benefits$, this.coupons$).map(
			([benefits, coupons]) =>
				indexBy(benefits.concat(coupons), benefit => benefit.$key!)
		);

		const benefitsGroupedByType$: Observable<
			Dictionary<Benefit[]>
		> = this.benefits$.map(benefits =>
			groupBy(benefits, benefit => benefit.type)
		);

		this.availableBenefits$ = combineLatest(
			benefitsGroupedByType$,
			this.coupons$,
			this.benefitTypeControl.valueChanges
		).pipe(
			map(
				([benefitsGrouped, coupons, selectedBenefitType]) =>
					selectedBenefitType === this.BenefitType.coupon
						? coupons
						: benefitsGrouped[selectedBenefitType] || []
			)
		);

		this.benefitTypeControl.valueChanges
			.pipe(distinctUntilChanged())
			.subscribe(() => {
				if (!this.benefitTypeControl.pristine) {
					this.benefitIdControl.setValue("");
				}
			});

		this.originalOffer$ = ((offerId
			? this.currentMerchantId$.switchMap(merchantId =>
					this.offersService.getOffer(merchantId, offerId)
			  )
			: Observable.of(this.offersService.getNewOffer()).pipe(
					delay(0)
			  )) as Observable<Offer>).pipe(
			filterNulls(),
			first(),
			shareReplay()
		);

		this.originalOffer$.subscribe((offer: Offer) => {
			this.offerForm.patchValue({
				benefitType: offer.benefit.type || "",
			});
			const expiresUnit: string = Object.keys(offer.expires || {})[0];
			this.offerForm.patchValue({
				benefitId: offer.benefit.key || "",
				criteria: offer.criteria || "",
				expiresQuantity: expiresUnit
					? (offer.expires[expiresUnit] as string)
					: "",
				expiresUnit: expiresUnit || "",
				location: offer.universal ? "all" : offer.location,
			});
			if (offer.status !== OfferStatus.new) {
				this.offerForm.disable();
			}
		});

		this.resultOffer$ = Observable.combineLatest(
			this.originalOffer$,
			this.offerForm.valueChanges,
			benefitsDictionary$
		)
			.map(([offer, formOffer, benefitsDictionary]) => {
				const formValue = this.offerForm.value;
				const benefitType = formOffer.benefitType as BenefitType;
				const newBenefit: BenefitSummary = formOffer.benefitId
					? SummaryFromBenefit(benefitsDictionary[formOffer.benefitId])
					: benefitType === BenefitType.coupon
						? {
								description: "",
								key: "",
								type: benefitType,
						  }
						: {
								amount: 0,
								currency: null,
								description: "",
								key: "",
								type: benefitType,
						  };
				const patch: Partial<Offer> = {
					benefit: newBenefit,
					criteria: formOffer.criteria,
					expires: {
						[formOffer.expiresUnit]: parseInt(formOffer.expiresQuantity, 10),
					},
					universal: formOffer.location === "all",
					...(formOffer.location !== "all"
						? {location: formOffer.location}
						: {}),
				};
				return {...offer, ...patch};
			})
			.shareReplay(1);

		//TODO: check auto resize() and delete
		// this.resultOffer$
		// 	.pipe(filterNulls())
		// 	.debounceTime(this.resizeDebounceTime)
		// 	.subscribe(() => {
		// 		this.content.resize();
		// 	});
	}

	cancelEdit() {
		this.nav.pop();
	}

	saveOffer(): Promise<any> {
		const savingOfferPromise = combineLatest(
			this.currentMerchantId$,
			this.resultOffer$
		)
			.pipe(first())
			.toPromise()
			.then(([merchantId, offer]) => {
				return (offer.$key
					? this.offersService.updateOffer(merchantId, offer)
					: this.offersService.addOffer(merchantId, offer)) as Promise<any>;
			})
			.then(() => this.nav.pop());

		this.busyService.setBusy(savingOfferPromise);
		return savingOfferPromise;
	}
}
