import {NgModule} from "@angular/core";
import {FlexLayoutModule} from "@angular/flex-layout";
import {IonicModule} from "@ionic/angular";
import {EffectsModule} from "@ngrx/effects";
import {StoreModule} from "@ngrx/store";
import {MovebeSharedModule} from "../../shared/movebe-shared.module";
import * as fromOfferSearch from "./+state/index";
import {OfferSearchComponent} from "./offer-search/offer-search.component";

@NgModule({
	declarations: [OfferSearchComponent],
	entryComponents: [],
	exports: [OfferSearchComponent],
	imports: [
		IonicModule,
		FlexLayoutModule,
		MovebeSharedModule,
		StoreModule.forFeature(
			fromOfferSearch.featureName,
			fromOfferSearch.reducer
		),
		EffectsModule.forFeature(fromOfferSearch.effects),
	],
	providers: [],
})
export class OfferSearchModule {}
