import {Component, Input} from "@angular/core";
import {Nav} from "@ionic/angular";
import {select, Store} from "@ngrx/store";
import {Merchant} from "../../../../lib/model/merchant.model";
import {Observable} from "../../../node_modules/rxjs/Observable";
import {ScanModal as ConsumerScanModal} from "../../app-modes/consumer/scan/scan.modal";
import * as fromMerchant from "../../app-modes/merchant/+state";
import {ChooseMerchantModal} from "../../app-modes/merchant/merchants/choose-merchant.modal";
import {ScanModal as MerchantScanModal} from "../../app-modes/merchant/scan/scan.modal";
import * as fromApp from "../../app/+state";
import {MovebeState} from "../../app/movebe-state.model";
import {AppMode} from "../../core/app-mode/app-mode.model";
import {GeolocationService} from "../../core/geolocation/geolocation.service";
import {Logger} from "../../core/logger/logger.service";
import {LatLngLiteral} from "../../core/mapping/lat-lng-literal";
import {PromiseModalController} from "../../core/modal/modal.service";
import * as fromUser from "../../lib/user/+state";
import {AccountModal} from "../../lib/user/account/account.modal";

@Component({
	selector: "ion-header[movebePageHeader]",
	styleUrls: ["./page-header.component.scss"],
	templateUrl: "./page-header.component.html",
})
export class PageHeaderComponent {
	@Input()
	title: string;
	readonly currentMerchant$: Observable<Merchant | undefined>;
	readonly appMode$: Observable<AppMode | undefined>;
	readonly AppMode = AppMode;
	readonly isUserSignedIn$: Observable<boolean>;
	readonly currentLatLng$: Observable<LatLngLiteral>;

	constructor(
		private logger: Logger,
		private nav: Nav,
		private store: Store<MovebeState>,
		private modalCtrl: PromiseModalController,
		private geolocationService: GeolocationService
	) {
		this.currentMerchant$ = this.store.pipe(
			select(fromMerchant.getCurrentMerchant)
		);
		this.appMode$ = this.store.pipe(select(fromApp.getAppMode));
		this.isUserSignedIn$ = this.store.pipe(select(fromUser.getIsUserSignedIn));
		this.currentLatLng$ = this.geolocationService.currentLatLng$;
	}

	openMerchantsPage() {
		this.modalCtrl.presentModal(ChooseMerchantModal);
	}

	openScanPage(appMode: AppMode) {
		this.modalCtrl.presentModal(
			appMode === AppMode.merchant ? MerchantScanModal : ConsumerScanModal
		);
	}

	openAccountPage() {
		this.modalCtrl.presentModal(AccountModal);
	}
}
