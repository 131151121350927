import {Component} from "@angular/core";
import {NavController} from "@ionic/angular";
import {Store} from "@ngrx/store";
import {Observable} from "rxjs/Observable";
import * as fromMerchant from "../+state/index";
import {
	Employee,
	MerchantUserStatus,
} from "../../../../../lib/model/employment-relationship.model";
import {MovebeState} from "../../../app/movebe-state.model";
import {Logger} from "../../../core/logger/logger.service";
import {PromiseModalController} from "../../../core/modal/modal.service";
import {
	UserInvitation,
	UserInvitationStatus,
} from "../../../core/user/user-invitation.model";
import {UserModal} from "../user/user.modal";

@Component({
	selector: "page-mm-users",
	styleUrls: ["./users.page.scss"],
	templateUrl: "./users.page.html",
})
export class UsersPage {
	MerchantUserStatus = MerchantUserStatus;
	UserInvitationStatus = UserInvitationStatus;

	readonly userInvitations$: Observable<UserInvitation[]>;
	readonly employees$: Observable<Employee[]>;

	constructor(
		private modalCtrl: PromiseModalController,
		private navCtrl: NavController,
		private logger: Logger,
		private store: Store<MovebeState>
	) {
		this.employees$ = this.store.select(fromMerchant.getMerchantEmployees);
		this.userInvitations$ = this.store.select(
			fromMerchant.getMerchantUserInvitations
		);
	}

	editUser(employee: Employee): void {
		this.modalCtrl.presentModal(UserModal, {employee});
	}
}
