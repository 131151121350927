import {Component} from "@angular/core";
import {NavController} from "@ionic/angular";
import {select, Store} from "@ngrx/store";
import {Observable} from "rxjs/Observable";
import {filter, first} from "rxjs/operators";
import * as fromMerchant from "../+state/index";
import {MerchantLocation} from "../../../../../lib/model/merchant-location.model";
import {MovebeState} from "../../../app/movebe-state.model";
import {Logger} from "../../../core/logger/logger.service";
import {PromiseModalController} from "../../../core/modal/modal.service";
import {AddLocationsModal} from "../add-locations/add-locations.modal";
import {LocationModal} from "../location/location.modal";

@Component({
	selector: "page-mm-locations",
	styleUrls: ["./locations.page.scss"],
	templateUrl: "./locations.page.html",
})
export class LocationsPage {
	async: any;
	readonly locations$: Observable<MerchantLocation[]>;

	constructor(
		private modalCtrl: PromiseModalController,
		private navCtrl: NavController,
		private logger: Logger,
		private store: Store<MovebeState>
	) {
		this.locations$ = this.store.pipe(
			select(fromMerchant.getMerchantLocations)
		);

		this.locations$
			.pipe(
				first(),
				filter(locations => locations.length === 0)
			)
			.subscribe(() => {
				this.addLocations();
			});
	}

	/*tslint:disable:member-ordering*/
	addLocations(): void {
		this.modalCtrl.presentModal(AddLocationsModal);
	}

	deleteLocation(event: MouseEvent, location: MerchantLocation): void {
		event.stopPropagation();
	}

	setLocationHidden(
		event: MouseEvent,
		location: MerchantLocation,
		hidden: boolean
	): void {
		event.stopPropagation();
	}

	editLocation(location: MerchantLocation): void {
		this.modalCtrl.presentModal(LocationModal, {
			location,
			locationId: location.$key,
		});
	}
}
