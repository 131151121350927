import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewChild,
} from "@angular/core";
import {Platform} from "@ionic/angular";
import {ZXingScannerComponent} from "@zxing/ngx-scanner";
import {AppLink} from "../../../../lib/model/app-link.model";
import {AppLinkService} from "../../core/app-links/app-link.service";
import {BusyService} from "../../core/busy/busy.service";
import {EffectsService} from "../../core/effects/effects.service";
import {ScanningService} from "../../core/scanning/scanning.service";

@Component({
	selector: "movebe-scan",
	styleUrls: ["scan.component.scss"],
	templateUrl: "scan.component.html",
})
export class ScanComponent implements OnInit {
	error = "";
	invalidCodeScanned = false;
	showScanner = false;
	noCamera: boolean;
	noPermission: boolean;

	availableCameras: MediaDeviceInfo[];
	selectedCamera: MediaDeviceInfo;

	@Input()
	scanMessage: string;

	@Output()
	scanSuccessful = new EventEmitter<AppLink>();

	@ViewChild("scanner")
	scanner: ZXingScannerComponent;

	constructor(
		private appLinkService: AppLinkService,
		private busyService: BusyService,
		private changeDetector: ChangeDetectorRef,
		private effectsService: EffectsService,
		private platform: Platform,
		private scanningService: ScanningService
	) {}

	ngOnInit(): void {
		if (!this.isCordova) {
			this.turnCameraOn();
		}
		this.scan();
	}

	initializeBrowserScan() {
		this.scanner.camerasFound.subscribe((cameras: MediaDeviceInfo[]) => {
			this.noCamera = cameras.length === 0;
			this.availableCameras = cameras;
			if (!this.noCamera) {
				//selects the phone's back camera by default
				const initalCamera: MediaDeviceInfo =
					cameras.find(camera =>
						/back|rear|environment/gi.test(camera.label)
					) || cameras[0];
				this.chooseCamera(initalCamera);
			}
		});

		this.scanner.camerasNotFound.subscribe((cameras: MediaDeviceInfo[]) => {
			this.noCamera = true;
		});

		this.scanner.permissionResponse.subscribe((answer: boolean) => {
			this.noPermission = !answer;
		});

		this.scanner.scanSuccess.subscribe((scannedText: string) =>
			this.dataScanned(scannedText)
		);
	}

	dataScanned(scannedText: string) {
		const appLink = this.appLinkService.parseAppLinkUrl(scannedText);
		if (appLink !== null) {
			this.turnCameraOff();
			this.effectsService.scanSuccessful();
			this.appLinkService.advertiseAppLinkEvent(appLink);
			this.scanSuccessful.emit(appLink);
		}
	}

	inputChanged(input) {
		this.dataScanned(input.valueOf());
	}

	turnCameraOn() {
		if (!this.showScanner) {
			this.showScanner = true; //tell angular to turn on the scanner component
			this.changeDetector.detectChanges(); //make sure angular has rendered the scanner component
			this.scanner.scannerEnabled = true; //and only then turn it on
			this.initializeBrowserScan();
		}
	}

	turnCameraOff() {
		if (!this.noCamera && this.scanner) {
			this.scanner.changeDeviceById("");
			this.scanner.scannerEnabled = false; //turn off the scanner component first
		}
		this.showScanner = false; //and then tell angular to turn it off
		this.changeDetector.detectChanges();
	}

	cycleNextCamera() {
		const currentCameraIndex = this.availableCameras.findIndex(
			camera => camera.deviceId === this.selectedCamera.deviceId
		);
		const nextCameraIndex =
			(currentCameraIndex + 1) % this.availableCameras.length;
		this.chooseCamera(this.availableCameras[nextCameraIndex]);
	}

	chooseCamera(camera: MediaDeviceInfo) {
		this.scanner.scannerEnabled = false;
		this.scanner.changeDevice(camera);
		this.selectedCamera = camera;
		this.scanner.scannerEnabled = true;
	}

	scan() {
		this.invalidCodeScanned = false;
		this.error = "";
		if (this.isCordova) {
			this.busyService.setBusy(
				this.scanningService
					.scan("")
					.then(scannedText => this.dataScanned(scannedText))
					.catch((error: Error) => {
						this.error = error.message;
						this.invalidCodeScanned = true;
					})
			);
		} else {
			this.showScanner = true;
		}
	}

	get isCordova(): boolean {
		return this.platform.is("cordova");
	}
}
