export enum ConnectionStatus {
	connected = "connected",
	noNetworkConnection = "noNetworkConnection",
	noFirebaseConnection = "noFirebaseConnection",
}

export interface DisconnectAlert {
	connectionStatus: ConnectionStatus;
	connectionStatusEndedPromise: Promise<ConnectionStatus>;
}
