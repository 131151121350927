import {Injectable} from "@angular/core";
import {AlertController, LoadingController} from "@ionic/angular";
import {Actions, Effect} from "@ngrx/effects";
import {Store} from "@ngrx/store";
import {TranslateService} from "@ngx-translate/core";
import {AngularFireAuth} from "angularfire2/auth";
import {ofType, toPayload} from "ts-action-operators";
import {BusyService} from "../../core/busy/busy.service";
import {FirebaseService} from "../../core/firebase/firebase.service";
import {Logger} from "../../core/logger/logger.service";
import {UserActions} from "../../lib/user/+state/user.actions";
import {MovebeState} from "../movebe-state.model";
import {AppActions} from "./app.actions";

@Injectable()
export class AppEffects {
	@Effect()
	readonly selectAppModeEffect$ = this.actions$
		.pipe(
			ofType(AppActions.SelectAppMode),
			toPayload()
		)
		.map(appMode => new UserActions.SelectAppMode(appMode));

	@Effect()
	readonly HttpErrorOccurredEffect$ = this.actions$
		.pipe(
			ofType(AppActions.HttpErrorOccurred),
			toPayload()
		)
		.concatMap(httpErrorResponse => {
			this.logger.error(httpErrorResponse);
			let message = httpErrorResponse.message;
			//tslint:disable:no-unsafe-any
			const isSqlError =
				!!httpErrorResponse.error.message &&
				!!httpErrorResponse.error.message.sql;
			if (isSqlError) {
				message = `${message}<br><br>
										<strong>${this.translate.instant(
											"ERROR.SQL_ERROR_DETAILS"
										) as string}:</strong><br><br>
										${httpErrorResponse.error.message.name}<br><br>
										${httpErrorResponse.error.message.code}<br><br>
										${httpErrorResponse.error.message.sqlMessage}<br><br>
										${httpErrorResponse.error.message.sql}`;
			}
			//tslint:enable:no-unsafe-any
			return this.alertCtrl
				.create({
					buttons: [this.translate.instant("LABEL.OK") as string],
					header: this.translate.instant(
						"ERROR.AN_ERROR_HAS_OCCURRED"
					) as string,
					message,
					subHeader: `${httpErrorResponse.status}:  ${
						httpErrorResponse.statusText
					}`,
				})
				.then(alert => alert.present());
		})
		.map(() => new AppActions.HttpErrorNotified());

	constructor(
		private actions$: Actions,
		private afAuth: AngularFireAuth,
		private alertCtrl: AlertController,
		private busyService: BusyService,
		private fb: FirebaseService,
		public loadingCtrl: LoadingController,
		private store: Store<MovebeState>,
		private translate: TranslateService,
		private logger: Logger
	) {}
}
