import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {AlertController, Nav, Tabs} from "@ionic/angular";
import {TranslateService} from "@ngx-translate/core";
import {combineLatest} from "rxjs";
import {Observable} from "rxjs/Observable";
import {filter, map, startWith} from "rxjs/operators";
import {Subject} from "rxjs/Subject";
import {AppLink, AppLinkType} from "../../../../../lib/model/app-link.model";
import {AppLinkService} from "../../../core/app-links/app-link.service";
import {Logger} from "../../../core/logger/logger.service";
import {MovebeMarkersService} from "../../../core/movebe-markers/movebe-markers.service";
import {RewardSearchFilter} from "../../../core/rewards/reward-search.model";
import {RewardsService} from "../../../core/rewards/rewards.service";
import {FindPage} from "../find/find.page";
import {HistoryPage} from "../history/history.page";
import {MarkerListPage} from "../markers/marker-list.page";
import {MarkerPage} from "../markers/marker.page";
import {RewardsPage} from "../rewards/rewards.page";

@Component({
	templateUrl: "./app.page.html",
})
export class AppPage implements OnInit, OnDestroy {
	@ViewChild("markersTab")
	markersTab;
	@ViewChild("movebeTabs")
	movebeTabs: Tabs;
	readonly appLinkEvent$: Observable<AppLink>;
	readonly done$ = new Subject<void>();
	readonly findPageRoot = FindPage;
	readonly historyPageRoot = HistoryPage;
	readonly markerListPageRoot = MarkerListPage;
	readonly vouchersPageRoot = RewardsPage;

	readonly historyCount$: Observable<number>;
	readonly rewardCount$: Observable<number>;
	readonly markerScansCount$: Observable<number>;
	readonly hideTabs$: Observable<boolean>;

	constructor(
		private appLinkService: AppLinkService,
		private alertCtrl: AlertController,
		private nav: Nav,
		private logger: Logger,
		private markersService: MovebeMarkersService,
		private rewardsService: RewardsService,
		private translate: TranslateService
	) {
		this.rewardCount$ = this.rewardsService
			.getCurrentUserRewards({filter: RewardSearchFilter.current})
			.pipe(map(rewards => rewards.length))
			.shareReplay();

		this.historyCount$ = this.rewardsService
			.getCurrentUserRewards({filter: RewardSearchFilter.old})
			.pipe(map(rewards => rewards.length))
			.shareReplay();

		this.markerScansCount$ = this.markersService
			.getCurrentUserRecentMarkerScans()
			.pipe(map(rewards => rewards.length))
			.shareReplay();

		this.hideTabs$ = combineLatest(
			this.rewardCount$,
			this.historyCount$,
			this.markerScansCount$
		).pipe(
			map(values => values.reduce((a, b) => a + b) === 0),
			startWith(true) // prevents jerky re-drawing of tab-bar during initialization (only draws tab bar after data has been retrieved to determine which buttons to show)
		);

		this.appLinkEvent$ = this.appLinkService.appLinkEvent$.takeUntil(
			this.done$
		);
	}

	ngOnInit() {
		this.initializeAppLinkHandling();
	}

	ngOnDestroy() {
		this.done$.next();
		this.done$.complete();
	}

	initializeAppLinkHandling() {
		this.appLinkEvent$
			.filter(appLink => appLink.type === AppLinkType.movebeMarker)
			.subscribe(appLinkEvent => {
				this.markersService
					.processMarkerScan(appLinkEvent.value)
					.then(scanId => {
						return this.nav.push(MarkerPage, {scanId});
					});
			});

		this.appLinkEvent$
			.pipe(filter(appLink => appLink.type === AppLinkType.scanRequest))
			.subscribe(appLink => {
				return this.alertCtrl
					.create({
						buttons: [this.translate.instant("LABEL.OK") as string],
						header: this.translate.instant(
							"SCAN_REQUEST.PERMISSION_DENIED"
						) as string,
						subHeader: this.translate.instant(
							"SCAN_REQUEST.YOU_MUST_BE_A_MERCHANT"
						) as string,
					})
					.then(alert => alert.present());
			});
	}
}
