import {Directive, ElementRef, Input} from "@angular/core";
import zenscroll from "zenscroll";

@Directive({
	selector: "[movebeZenscroll]",
})
export class ZenscrollDirective {
	@Input()
	duration = 500;
	@Input()
	edgeOffset = 30;
	private scroller: any;

	constructor(private el: ElementRef) {
		this.scroller = zenscroll.createScroller(
			el.nativeElement,
			this.duration,
			this.edgeOffset
		); /*tslint:disable-line:no-unsafe-any*/
	}

	@Input() /*tslint:disable-line:no-unsafe-any*/
	set scrollTop(scrollTop: number) {
		this.scroller.toY(
			scrollTop,
			this.duration
		); /*tslint:disable-line:no-unsafe-any*/
	}
}
