import {FirebaseEntity} from "./firebase-entity.model";
import {MerchantSummary} from "./merchant.model";
import {UserSummary} from "./user/user-profile.model";

export enum MerchantRole {
	agent = "agent",
	manager = "manager",
	owner = "owner",
}

export enum MerchantUserStatus {
	active = "active",
	inactive = "inactive",
}

export interface EmploymentRelationship {
	location?: string;
	role: MerchantRole;
	status: MerchantUserStatus;
	universal: boolean;
}

export interface Employee extends FirebaseEntity {
	employmentRelationship: EmploymentRelationship;
	user: UserSummary;
}

export interface Employer extends FirebaseEntity {
	merchant: MerchantSummary;
	employmentRelationship: EmploymentRelationship;
}
