import {Action} from "@ngrx/store";
import {isType} from "ts-action";
import {Employer} from "../../../../../../lib/model/employment-relationship.model";
import {CurrentUserActions} from "./current-user.actions";

export interface CurrentUserState {
	employers: Employer[];
}

const defaultState: CurrentUserState = {
	employers: [],
};

export function currentUserReducer(
	state: CurrentUserState = defaultState,
	action: Action
): CurrentUserState {
	if (isType(action, CurrentUserActions.EmployersReceived)) {
		return {...state, employers: action.payload};
	}
	return state;
}

export const getEmployers = (state: CurrentUserState) => state.employers;
