import {LatLngLiteral} from "../../app/src/core/mapping/lat-lng-literal";
import {BenefitType} from "./benefit.model";

export enum RedemptionType {
	allocated = "allocated",
}

export interface Redemption {
	amount: number;
	benefitType: BenefitType;
	consumerUserId: string;
	coordinates: LatLngLiteral;
	currency: string;
	fee?: number;
	allocationId?: number;
	locationId: string;
	merchantId: string;
	merchantUserId: string;
	providerId?: string;
	rewardId: string;
	type: RedemptionType;
}
