import {Component, OnInit} from "@angular/core";
import {ModalController, NavParams} from "@ionic/angular";
import {BehaviorSubject} from "rxjs/BehaviorSubject";
import {Observable} from "rxjs/Observable";
import {MerchantLocation} from "../../../../../../lib/model/merchant-location.model";
import {Logger} from "../../../../core/logger/logger.service";
import {Point} from "../../../../core/misc/point.model";
import {FloorPlan} from "../../../../core/movebe-markers/floor-plan.model";
import {MarkerCodeGeotag} from "../../../../core/movebe-markers/marker-code-geotag.model";
import {MovebeMarkersService} from "../../../../core/movebe-markers/movebe-markers.service";

@Component({
	selector: "page-mm-floor-plan-modal",
	styleUrls: ["floor-plan.modal.scss"],
	templateUrl: "floor-plan.modal.html",
})
export class FloorPlanModal implements OnInit {
	floorPlans$: Observable<FloorPlan[]>;
	location: MerchantLocation;
	markerCodeGeotag: MarkerCodeGeotag;
	position: Point;
	selectedFloorPlan$: Observable<FloorPlan | undefined>;
	selectedFloorPlanId$ = new BehaviorSubject<string>("");

	constructor(
		private modalController: ModalController,
		private navParams: NavParams,
		private logger: Logger,
		private movebeMarkersService: MovebeMarkersService
	) {
		this.markerCodeGeotag = navParams.get("markerCode") as MarkerCodeGeotag;
		this.location = navParams.get("location") as MerchantLocation;
	}

	ngOnInit(): void {
		this.floorPlans$ = this.movebeMarkersService.getFloorPlans(
			this.location.merchantId,
			this.location.$key!
		);

		this.selectedFloorPlan$ = Observable.combineLatest(
			this.floorPlans$,
			this.selectedFloorPlanId$,
			(floorPlans, selectedFloorPlanId) => {
				return floorPlans.find(
					floorPlan => floorPlan.$key === selectedFloorPlanId
				);
			}
		);

		if (this.markerCodeGeotag.floorPlanPlacement) {
			this.position = this.markerCodeGeotag.floorPlanPlacement.position;
			this.selectedFloorPlanId$.next(
				this.markerCodeGeotag.floorPlanPlacement.floorPlanId
			);
		}
	}

	positionChanged(position: Point) {
		this.position = position;
	}

	cancel() {
		this.modalController.dismiss(false);
	}

	dismiss(success: boolean) {
		this.modalController.dismiss({
			floorPlanId: this.selectedFloorPlanId$.value,
			position: this.position,
		});
	}
}
