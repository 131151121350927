import {firestore} from "firebase/app";
import "firebase/firestore";

export class Timestamp extends firestore.Timestamp {}

export function isTimestamp(value: any): value is Timestamp {
	return (
		typeof value.nanoseconds === "number" &&
		typeof value.seconds === "number" &&
		typeof value.toDate === "function"
	);
}
