import {action, payload} from "ts-action";
import {ChatMessage} from "../../../../../lib/model/chat/chat-message.model";
import {Chat} from "../../../../../lib/model/chat/chat.model";

export namespace ChatActions {
	export const StartChat = action("[Chat] Start Chat");

	export const EndChat = action("[Chat] End Chat");

	export const ChatReceived = action("[Chat] Chat Received", payload<Chat>());

	export const ChatMessagesReceived = action(
		"[Chat] Chat Messages Received",
		payload<ChatMessage[]>()
	);

	export const SendMessage = action("[Chat] Send Message", payload<string>());

	export const MessageSent = action("[Chat] Message Sent");
}
