import {SurveyQuestionType} from "../../../../lib/model/survey/survey-question.model";
import {Survey} from "../../../../lib/model/survey/survey.model";

export const surveysData: Survey[] = [
	{
		name: "Merchant Customer Satisfaction",
		questions: [
			{
				choices: [
					{
						label: "Definitely Not",
						value: 1,
					},
					{
						label: "Probably Not",
						value: 2,
					},
					{
						label: "Probably",
						value: 3,
					},
					{
						label: "Definitely",
						value: 4,
					},
				],
				isSatisfactionIndicator: true,
				required: true,
				text: "How likely are you to return to {merchantName}?",
				type: SurveyQuestionType.multipleChoice,
			},
			{
				choices: [
					{
						label: "Not at all",
						value: 1,
					},
					{
						label: "Slightly",
						value: 2,
					},
					{
						label: "Very",
						value: 3,
					},
					{
						label: "Extremely",
						value: 4,
					},
				],
				isSatisfactionIndicator: false,
				required: true,
				text:
					"How much did this offer impact your decision to go to {merchantName}?",
				type: SurveyQuestionType.multipleChoice,
			},
			{
				isSatisfactionIndicator: true,
				placeholder: "Feedback",
				required: false,
				text: "Feedback for {merchantName} management",
				type: SurveyQuestionType.freeform,
			},
		],
	},
];
