import {Component} from "@angular/core";
import {NavParams} from "@ionic/angular";
import {Observable} from "rxjs/Observable";
import {Merchant} from "../../../../../lib/model/merchant.model";
import {RewardDetails} from "../../../../../lib/model/reward.model";
import {MerchantsService} from "../../../core/merchants/merchants.service";

@Component({
	selector: "page-admin-recent-validations",
	templateUrl: "./recent-validations.page.html",
})
export class RecentValidationsPage {
	merchant: Merchant;

	readonly num = 20;
	readonly recentValidations$: Observable<RewardDetails[]>;

	constructor(
		private merchantsService: MerchantsService,
		private navParams: NavParams
	) {
		this.merchant = this.navParams.get("merchant") as Merchant;
		this.recentValidations$ = this.merchantsService
			.getRecentMerchantValidations(this.merchant.$key!, this.num)
			.shareReplay();
	}
}
