import {Injectable} from "@angular/core";
import {Actions, Effect} from "@ngrx/effects";
import {Action, select, Store} from "@ngrx/store";
import {Observable} from "rxjs/Observable";
import {map, switchMap} from "rxjs/operators";
import {ofType, toPayload} from "ts-action-operators";
import {Employee} from "../../../../../../lib/model/employment-relationship.model";
import {MerchantLocation} from "../../../../../../lib/model/merchant-location.model";
import {
	Merchant,
	MerchantStatus,
} from "../../../../../../lib/model/merchant.model";
import {Offer} from "../../../../../../lib/model/offer.model";
import {RewardDetails} from "../../../../../../lib/model/reward.model";
import {MovebeState} from "../../../../app/movebe-state.model";
import {FirebaseService} from "../../../../core/firebase/firebase.service";
import {FirestoreService} from "../../../../core/firebase/firestore.service";
import {MerchantsService} from "../../../../core/merchants/merchants.service";
import {OffersService} from "../../../../core/offers/offers.service";
import {RewardsService} from "../../../../core/rewards/rewards.service";
import {UserInvitation} from "../../../../core/user/user-invitation.model";
import * as fromUser from "../../../../lib/user/+state";
import {CurrentUserActions} from "../current-user/current-user.actions";
import * as fromMerchant from "../index";
import {CurrentMerchantActions} from "./current-merchant.actions";

@Injectable()
export class CurrentMerchantEffects {
	readonly recentValidationsToGet = 9;

	@Effect()
	readonly queryCurrentEmployerEffect$: Observable<Action>;
	@Effect()
	readonly queryMerchantEffectGetInvitations$: Observable<Action>;
	@Effect()
	readonly queryMerchantEffectGetLocations$: Observable<Action>;
	@Effect()
	readonly queryMerchantEffectGetMerchant$: Observable<Action>;
	@Effect()
	readonly queryMerchantEffectGetOffers$: Observable<Action>;
	@Effect()
	readonly queryMerchantEffectGetEmployees$: Observable<Action>;
	@Effect()
	readonly queryMerchantEffectGetRecentValidations$: Observable<Action>;
	@Effect()
	readonly selectFirstMerchantEffect$: Observable<Action>;

	constructor(
		private rewardsService: RewardsService,
		private offersService: OffersService,
		private actions$: Actions,
		private fb: FirebaseService,
		private firestore: FirestoreService,
		private merchantsService: MerchantsService,
		private store: Store<MovebeState>
	) {
		this.queryCurrentEmployerEffect$ = this.actions$.pipe(
			ofType(CurrentMerchantActions.QueryCurrentEmployer),
			switchMap(() => this.store.pipe(select(fromUser.getCurrentMerchant))),
			map(selectedEmployer => {
				return selectedEmployer
					? new CurrentMerchantActions.QueryMerchant(
							selectedEmployer.merchant.key
					  )
					: new CurrentMerchantActions.SelectFirstMerchant();
			})
		);

		this.selectFirstMerchantEffect$ = this.actions$
			.pipe(ofType(CurrentMerchantActions.SelectFirstMerchant))
			.zip(this.actions$.pipe(ofType(CurrentUserActions.EmployersReceived)))
			.pipe(
				switchMap(() => this.getDefaultCurrentMerchant()),
				map(merchantId => {
					return new CurrentMerchantActions.QueryMerchant(merchantId);
				})
			);

		this.queryMerchantEffectGetMerchant$ = this.actions$.pipe(
			ofType(CurrentMerchantActions.QueryMerchant),
			toPayload(),
			switchMap(payload => this.getMerchant(payload)),
			map(
				merchant =>
					new CurrentMerchantActions.CurrentMerchantReceived(merchant!)
			)
		);

		this.queryMerchantEffectGetLocations$ = this.actions$.pipe(
			ofType(CurrentMerchantActions.QueryMerchant),
			toPayload(),
			switchMap(payload => this.getCurrentMerchantLocations(payload)),
			map(
				merchantLocations =>
					new CurrentMerchantActions.LocationsReceived(merchantLocations)
			)
		);

		this.queryMerchantEffectGetOffers$ = this.actions$.pipe(
			ofType(CurrentMerchantActions.QueryMerchant),
			toPayload(),
			switchMap(payload => this.getCurrentMerchantOffers(payload)),
			map(offers => new CurrentMerchantActions.OffersReceived(offers))
		);

		this.queryMerchantEffectGetInvitations$ = this.actions$.pipe(
			ofType(CurrentMerchantActions.QueryMerchant),
			toPayload(),
			switchMap(payload => this.getCurrentMerchantUserInvitations(payload)),
			map(
				invitations =>
					new CurrentMerchantActions.UserInvitationsReceived(invitations)
			)
		);

		this.queryMerchantEffectGetEmployees$ = this.actions$.pipe(
			ofType(CurrentMerchantActions.QueryMerchant),
			toPayload(),
			switchMap(payload => this.getCurrentMerchantEmployees(payload)),
			map(users => new CurrentMerchantActions.UsersReceived(users))
		);

		this.queryMerchantEffectGetRecentValidations$ = this.actions$.pipe(
			ofType(CurrentMerchantActions.QueryMerchant),
			toPayload(),
			switchMap(payload =>
				this.getCurrentMerchantRecentValidations(
					payload,
					this.recentValidationsToGet
				)
			),
			map(rewardDetails => {
				return new CurrentMerchantActions.RecentValidationsReceived(
					rewardDetails
				);
			})
		);
	}

	private getDefaultCurrentMerchant(): Observable<string> {
		return this.store.pipe(select(fromMerchant.getEmployers)).map(employers => {
			const approvedMerchants = employers.filter(
				merchant => merchant.merchant.status === MerchantStatus.approved
			);
			return approvedMerchants.length
				? approvedMerchants[0].$key!
				: employers[0].$key!;
			//TODO: handle correct if there is no merchant
		});
	}

	private getMerchant(merchantId: string): Observable<Merchant | null> {
		return this.firestore.toObjectStream(
			this.firestore.getMerchant(merchantId)
		);
	}

	private getCurrentMerchantLocations(
		merchantId: string
	): Observable<MerchantLocation[]> {
		return this.firestore.toListStream(
			this.firestore.getMerchantLocations(merchantId)
		);
	}

	private getCurrentMerchantOffers(id: string): Observable<Offer[]> {
		return this.offersService.getOffers(id);
	}

	private getCurrentMerchantUserInvitations(
		id: string
	): Observable<UserInvitation[]> {
		return this.merchantsService.getUserInvitations(id);
	}

	private getCurrentMerchantEmployees(id: string): Observable<Employee[]> {
		return this.firestore.toListStream(this.firestore.getMerchantEmployees(id));
	}

	private getCurrentMerchantRecentValidations(
		merchantId: string,
		count: number
	): Observable<RewardDetails[]> {
		return this.merchantsService.getRecentMerchantValidations(
			merchantId,
			count
		);
	}
}
