import {Component, ViewChild} from "@angular/core";
import {ModalController} from "@ionic/angular";
import {Logger} from "../../../core/logger/logger.service";
import {ScanComponent} from "../../../shared/scanning/scan.component";

@Component({
	selector: "page-mm-scan",
	styleUrls: ["scan.modal.scss"],
	templateUrl: "scan.modal.html",
})
export class ScanModal {
	@ViewChild(ScanComponent)
	scanComponent: ScanComponent;

	constructor(private logger: Logger, private modalCtrl: ModalController) {}

	ionViewDidEnter() {
		this.scanComponent.scan();
	}

	dismiss() {
		this.modalCtrl.dismiss();
	}
}
