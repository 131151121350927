import {Component, Input, OnChanges, ViewChild} from "@angular/core";
import {AlertController, Button, ModalController} from "@ionic/angular";
import {TranslateService} from "@ngx-translate/core";
import {Observable} from "rxjs/Observable";
import {MerchantLocation} from "../../../../../../lib/model/merchant-location.model";
import {Logger} from "../../../../core/logger/logger.service";
import {FloorPlan} from "../../../../core/movebe-markers/floor-plan.model";
import {MarkerDescriptor} from "../../../../core/movebe-markers/marker-descriptor.model";
import {MovebeMarkersService} from "../../../../core/movebe-markers/movebe-markers.service";
import {UploadFloorPlanModal} from "../upload-floor-plan-modal/upload-floor-plan.modal";

@Component({
	selector: "movebe-location-descriptor-editor",
	styleUrls: ["./location-descriptor-editor.component.scss"],
	templateUrl: "./location-descriptor-editor.component.html",
})
export class LocationDescriptorEditorComponent implements OnChanges {
	@Input()
	location: MerchantLocation;

	@ViewChild("addDescriptorButton")
	addDescriptorButton: Button;
	@ViewChild("descriptorNameInput")
	descriptorNameInput: Input;
	@ViewChild("floorPlanNameInput")
	floorPlanNameInput: Input;

	floorPlans: FloorPlan[];
	floorPlans$: Observable<FloorPlan[]>;
	markerDescriptors$: Observable<MarkerDescriptor[]>;

	constructor(
		private alertCtrl: AlertController,
		private logger: Logger,
		private modalCtrl: ModalController,
		private movebeMarkersService: MovebeMarkersService,
		private translate: TranslateService
	) {}

	ngOnChanges(): void {
		this.markerDescriptors$ = this.movebeMarkersService.getMarkerDescriptors(
			this.location.$key!
		);

		this.floorPlans$ = this.movebeMarkersService.getFloorPlans(
			this.location.merchantId,
			this.location.$key!
		);

		this.floorPlans$.subscribe(floorPlans => (this.floorPlans = floorPlans));
	}

	async openFloorPlanModal() {
		const modal = await this.modalCtrl.create({
			component: UploadFloorPlanModal,
		});

		modal.onDidDismiss().then(details => {
			const data = details.data;
			this.movebeMarkersService.uploadFloorPlan(
				this.location.merchantId,
				this.location.$key!,
				data.floorPlanName,
				data.imageBlob
			);
		});

		modal.present();
	}

	promptDescriptorName() {
		this.alertCtrl
			.create({
				buttons: [
					{
						text: this.translate.instant("LABEL.CANCEL") as string,
					},
					{
						handler: (data: {descriptorName: string}) =>
							this.addDescriptor(data.descriptorName),
						text: this.translate.instant("LABEL.SAVE") as string,
					},
				],
				header: this.translate.instant(
					"MOVEBE_MARKER.ADD_DESCRIPTOR"
				) as string,
				inputs: [
					{
						name: "descriptorName",
						placeholder: this.translate.instant(
							"MOVEBE_MARKER.DESCRIPTOR_NAME_EXAMPLE"
						) as string,
						type: "text",
					},
				],
				message: this.translate.instant(
					"MOVEBE_MARKER.ENTER_DESCRIPTOR_NAME"
				) as string,
			})
			.then(alert => alert.present());
	}

	addDescriptor(descriptorName: string) {
		this.movebeMarkersService.addMarkerDescriptor(
			this.location.$key!,
			descriptorName
		);
	}

	deleteDescriptor(descriptor: MarkerDescriptor) {
		this.movebeMarkersService.deleteMarkerDescriptor(
			this.location.$key!,
			descriptor.$key!
		);
	}

	promptDescriptorValue(descriptor: MarkerDescriptor) {
		this.alertCtrl
			.create({
				buttons: [
					{
						text: this.translate.instant("LABEL.CANCEL") as string,
					},
					{
						handler: (data: {descriptorValues: string}) =>
							this.addDescriptorValues(descriptor, data.descriptorValues),
						text: this.translate.instant("LABEL.SAVE") as string,
					},
				],
				header: this.translate.instant(
					"MOVEBE_MARKER.ADD_DESCRIPTOR_VALUES"
				) as string,
				inputs: [
					{
						name: "descriptorValues",
						type: "text",
					},
				],
				message: this.translate.instant(
					"MOVEBE_MARKER.ENTER_DESCRIPTORS"
				) as string,
			})
			.then(alert => alert.present());
	}

	addDescriptorValues(descriptor: MarkerDescriptor, newValues: string) {
		newValues.split(/,\s*/).forEach(val => {
			this.movebeMarkersService.addMarkerDescriptorValue(
				this.location.$key,
				descriptor.$key!,
				val
			);
		});
	}

	deleteMarkerDescriptorValue(descriptor: MarkerDescriptor, valueId: string) {
		this.movebeMarkersService.deleteMarkerDescriptorValue(
			this.location.$key!,
			descriptor.$key!,
			valueId
		);
	}
}
