import {BenefitType, Coupon} from "../../../../lib/model/benefit.model";
import {Country, Currency} from "../../../../lib/model/country.model";
import {Dictionary} from "../../../../lib/model/dictionary.model";
import {Language} from "../../../../lib/model/language.model";
import {Merchant, MerchantStatus} from "../../../../lib/model/merchant.model";
import {OfferStatus} from "../../../../lib/model/offer.model";
import {BarCodeType} from "../../core/coupon/coupon.model";

export interface MerchantInfo {
	coupons: Dictionary<Coupon>;
	offers: any[];
	profile: Merchant;
	searchName: string;
	survey?: string;
}

export const merchantData: MerchantInfo[] = [
	{
		coupons: {},
		offers: [
			{
				benefit: {
					key: "fuel-cad-05000",
					type: BenefitType.fuel,
				},
				criteria: "Spend three nights or more",
				expires: {
					hours: 24,
				},
				requiredValidations: 1,
				sortPosition: 0,
				status: OfferStatus.activated,
				universal: true,
			},
		],
		profile: {
			country: Country.CA,
			currency: Currency.CAD,
			description: "Best night's sleep at the best price",
			flatFee: 0.45,
			homepage: "http://www.sleephere.ca",
			languages: [Language.en],
			name: "Holiday Home",
			percentFee: 3.5,
			referringPartner: "movebe",
			status: MerchantStatus.approved,
			survey: "merchant-customer-satisfaction",
			telephone: "123-4567",
			type: "lodging",
		},
		searchName: "Holiday Inn",
	},
	{
		coupons: {},
		offers: [
			{
				benefit: {
					key: "ferry-cad-01000",
					type: BenefitType.ferry,
				},
				criteria: "Order dinner before 6:00pm",
				expires: {
					hours: 12,
				},
				requiredValidations: 1,
				sortPosition: 0,
				status: OfferStatus.activated,
				universal: true,
			},
		],
		profile: {
			country: Country.CA,
			currency: Currency.CAD,
			description: "Best Island Food Around",
			flatFee: 0.45,
			homepage: "http://www..isleofview.ca",
			languages: [Language.en],
			name: "Isle of View",
			percentFee: 3.5,
			referringPartner: "movebe",
			status: MerchantStatus.approved,
			survey: "merchant-customer-satisfaction",
			telephone: "123-4567",
			type: "restaurant",
		},
		searchName: "Rectory Cafe",
	},
	{
		coupons: {},
		offers: [
			{
				benefit: {
					key: "toll-cad-01500",
					type: BenefitType.toll,
				},
				criteria: "Spend the night",
				expires: {
					hours: 12,
				},
				requiredValidations: 1,
				sortPosition: 0,
				status: OfferStatus.activated,
				universal: true,
			},
		],
		profile: {
			country: Country.CA,
			currency: Currency.CAD,
			description: "Your home when you are not at home",
			flatFee: 0.45,
			homepage: "http://www.sheraton.ca",
			languages: [Language.en],
			name: "Cozy Nights",
			percentFee: 3.5,
			referringPartner: "movebe",
			status: MerchantStatus.approved,
			survey: "merchant-customer-satisfaction",
			telephone: "123-4567",
			type: "lodging",
		},
		searchName: "Best Western Parkway",
	},
	{
		coupons: {},
		offers: [
			{
				benefit: {
					key: "bike-share-cad-00500",
					type: BenefitType.bikeShare,
				},
				criteria: "Get any food item and a drink",
				expires: {
					hours: 12,
				},
				requiredValidations: 1,
				sortPosition: 0,
				status: OfferStatus.activated,
				universal: true,
			},
		],
		profile: {
			country: Country.CA,
			currency: Currency.CAD,
			description: "Treat yourself to a treat",
			flatFee: 0.45,
			homepage: "http://www.platypustails.ca",
			languages: [Language.en],
			name: "Platypus Tails",
			percentFee: 3.5,
			referringPartner: "movebe",
			status: MerchantStatus.approved,
			survey: "merchant-customer-satisfaction",
			telephone: "123-4567",
			type: "restaurant",
		},
		searchName: "Beaver Tails",
	},
	{
		coupons: {},
		offers: [
			{
				benefit: {
					key: "parking-cad-00500",
					type: BenefitType.parking,
				},
				criteria: "Spend $30 or more",
				expires: {
					hours: 12,
				},
				requiredValidations: 1,
				sortPosition: 0,
				status: OfferStatus.activated,
				universal: true,
			},
			{
				benefit: {
					key: "parking-cad-01000",
					type: BenefitType.parking,
				},
				criteria: "Spend $50 or more",
				expires: {
					hours: 12,
				},
				requiredValidations: 1,
				sortPosition: 1,
				status: OfferStatus.activated,
				universal: true,
			},
		],
		profile: {
			country: Country.CA,
			currency: Currency.CAD,
			description: "Canada's Drug Store",
			flatFee: 0.45,
			homepage: "http://www.superpharm.ca",
			languages: [Language.en],
			name: "Super Pharm",
			percentFee: 3.5,
			referringPartner: "movebe",
			status: MerchantStatus.approved,
			survey: "merchant-customer-satisfaction",
			telephone: "123-4567",
			type: "pharmacy",
		},
		searchName: "Shoppers Drug Mart",
	},
	{
		coupons: {},
		offers: [
			{
				benefit: {
					key: "parking-cad-02000",
					type: BenefitType.parking,
				},
				criteria: "Get any one hour treatment between 9:30am and 4:00 pm",
				expires: {
					hours: 12,
				},
				requiredValidations: 1,
				sortPosition: 0,
				status: OfferStatus.activated,
				universal: true,
			},
			{
				benefit: {
					key: "parking-cad-01000",
					type: BenefitType.parking,
				},
				criteria: "Spend $50 or more",
				expires: {
					hours: 12,
				},
				requiredValidations: 1,
				sortPosition: 1,
				status: OfferStatus.activated,
				universal: true,
			},
		],
		profile: {
			country: Country.CA,
			currency: Currency.CAD,
			description: "World's best spa",
			flatFee: 0.45,
			homepage: "http://www.naturalspa.ca",
			languages: [Language.en],
			name: "Natural Beauty",
			percentFee: 3.5,
			referringPartner: "movebe",
			status: MerchantStatus.approved,
			survey: "merchant-customer-satisfaction",
			telephone: "123-4567",
			type: "pharmacy",
		},
		searchName: "Hand and Stone",
	},
	{
		coupons: {},
		offers: [
			{
				benefit: {
					key: "parking-cad-00500",
					type: BenefitType.parking,
				},
				criteria: "Get the lunch special",
				expires: {
					hours: 12,
				},
				requiredValidations: 1,
				sortPosition: 0,
				status: OfferStatus.activated,
				universal: true,
			},
			{
				benefit: {
					key: "parking-cad-01000",
					type: BenefitType.parking,
				},
				criteria: "Spend $50 or more",
				expires: {
					hours: 12,
				},
				requiredValidations: 1,
				sortPosition: 1,
				status: OfferStatus.activated,
				universal: true,
			},
		],
		profile: {
			country: Country.CA,
			currency: Currency.CAD,
			description: "Best Burgers",
			flatFee: 0.45,
			homepage: "http://www.eathere.ca",
			languages: [Language.en],
			name: "Mike Donald's",
			percentFee: 3.5,
			referringPartner: "movebe",
			status: MerchantStatus.approved,
			survey: "merchant-customer-satisfaction",
			telephone: "123-4567",
			type: "restaurant",
		},
		searchName: "McDonald's",
	},
	{
		coupons: {
			"20-off": {
				barCodeType: BarCodeType.qr,
				barCodeValue: "476584562456",
				description: "get $20 off",
				title: "20 Dollars Off",
				type: BenefitType.coupon,
			},
			"5-off": {
				barCodeType: BarCodeType.qr,
				barCodeValue: "4564256213456",
				description: "get $5 off",
				title: "5 Dollars Off",
				type: BenefitType.coupon,
			},
		},
		offers: [
			{
				benefit: {
					key: "5-off",
					type: BenefitType.coupon,
				},
				criteria: "Purchase any new video game",
				expires: {
					hours: 12,
				},
				requiredValidations: 1,
				sortPosition: 0,
				status: OfferStatus.activated,
				universal: true,
			},
			{
				benefit: {
					key: "20-off",
					type: BenefitType.coupon,
				},
				criteria: "Purchase any game console",
				expires: {
					hours: 12,
				},
				requiredValidations: 1,
				sortPosition: 1,
				status: OfferStatus.activated,
				universal: true,
			},
			{
				benefit: {
					key: "parking-cad-00500",
					type: BenefitType.parking,
				},
				criteria: "Spend $25 or more",
				expires: {
					hours: 12,
				},
				requiredValidations: 1,
				sortPosition: 2,
				status: OfferStatus.activated,
				universal: true,
			},
		],
		profile: {
			country: Country.CA,
			currency: Currency.CAD,
			description: "Simply the Best",
			flatFee: 0.45,
			homepage: "http://www.bestbuy.ca",
			languages: [Language.en],
			name: "Better Buy",
			percentFee: 3.5,
			referringPartner: "movebe",
			status: MerchantStatus.approved,
			survey: "merchant-customer-satisfaction",
			telephone: "123-4567",
			type: "store",
		},
		searchName: "Best Buy",
	},
	{
		coupons: {
			"free-car-wash": {
				barCodeType: BarCodeType.qr,
				barCodeValue: "3456453635467",
				description: "Get a free car wash on your next visit",
				title: "Car Wash",
				type: BenefitType.coupon,
			},
		},
		offers: [
			{
				benefit: {
					key: "free-car-wash",
					type: BenefitType.coupon,
				},
				criteria: "Get a tune up or oil change",
				expires: {
					months: 6,
				},
				requiredValidations: 1,
				sortPosition: 0,
				status: OfferStatus.activated,
				universal: true,
			},
		],
		profile: {
			country: Country.CA,
			currency: Currency.CAD,
			description: "There's a lot more to American Tire than just tires",
			flatFee: 0.45,
			homepage: "http://www.canadiantire.ca",
			languages: [Language.en],
			name: "American Tire",
			percentFee: 3.5,
			referringPartner: "movebe",
			status: MerchantStatus.approved,
			survey: "merchant-customer-satisfaction",
			telephone: "123-4567",
			type: "store",
		},
		searchName: "Canadian Tire",
	},
	{
		coupons: {},
		offers: [
			{
				benefit: {
					key: "parking-cad-00250",
					type: BenefitType.parking,
				},
				criteria: "Order the daily special between 1:30 and 4:00pm",
				expires: {
					hours: 12,
				},
				requiredValidations: 1,
				sortPosition: 0,
				status: OfferStatus.activated,
				universal: true,
			},
			{
				benefit: {
					key: "transit-cad-00300",
					type: BenefitType.transit,
				},
				criteria: "Spend $10 or more",
				expires: {
					hours: 12,
				},
				requiredValidations: 1,
				sortPosition: 1,
				status: OfferStatus.activated,
				universal: true,
			},
		],
		profile: {
			country: Country.CA,
			currency: Currency.CAD,
			description: "Coffee for all",
			flatFee: 0.45,
			homepage: "http://www.thirdcup.ca",
			languages: [Language.en],
			name: "Third Cup",
			percentFee: 3.5,
			referringPartner: "movebe",
			status: MerchantStatus.approved,
			survey: "merchant-customer-satisfaction",
			telephone: "123-4567",
			type: "restaurant",
		},
		searchName: "Second Cup",
	},
	{
		coupons: {},
		offers: [
			{
				benefit: {
					key: "parking-cad-01000",
					type: BenefitType.parking,
				},
				criteria: "Spend $50 on your total bill",
				expires: {
					hours: 12,
				},
				requiredValidations: 1,
				sortPosition: 0,
				status: OfferStatus.activated,
				universal: true,
			},
			{
				benefit: {
					key: "parking-cad-02000",
					type: BenefitType.parking,
				},
				criteria: "Spend $100 on your total bill",
				expires: {
					hours: 12,
				},
				requiredValidations: 1,
				sortPosition: 1,
				status: OfferStatus.activated,
				universal: true,
			},
			{
				benefit: {
					key: "ride-hail-cad-01500",
					type: BenefitType.rideHail,
				},
				criteria: "Spend $50 or more",
				expires: {
					hours: 12,
				},
				requiredValidations: 1,
				sortPosition: 2,
				status: OfferStatus.activated,
				universal: true,
			},
		],
		profile: {
			country: Country.CA,
			currency: Currency.CAD,
			description: "Good Beer. Good Food. Great times.",
			flatFee: 0.45,
			homepage: "http://www.amsterdambrewery.ca",
			languages: [Language.en],
			name: "Beer House",
			percentFee: 3.5,
			referringPartner: "movebe",
			status: MerchantStatus.approved,
			survey: "merchant-customer-satisfaction",
			telephone: "123-4567",
			type: "restaurant",
		},
		searchName: "Amsterdam Brewery",
	},
	{
		coupons: {
			"15-off": {
				barCodeType: BarCodeType.qr,
				barCodeValue: "78698756234856",
				description: "Get 15% off",
				title: "15% off",
				type: BenefitType.coupon,
			},
			"free-dessert": {
				barCodeType: BarCodeType.qr,
				barCodeValue: "3456453635467",
				description: "Get a free dessert",
				title: "Free Dessert",
				type: BenefitType.coupon,
			},
			"one-free-coffee": {
				barCodeType: BarCodeType.qr,
				barCodeValue: "4567324567426",
				description: "Get any free hot beverage",
				title: "Get a Free Coffee",
				type: BenefitType.coupon,
			},
			"one-free-lunch": {
				barCodeType: BarCodeType.qr,
				barCodeValue: "7896789678967",
				description: "Get a free hot lunch item",
				title: "Get a Free Lunch",
				type: BenefitType.coupon,
			},
		},
		offers: [
			{
				benefit: {
					key: "parking-cad-00500",
					type: BenefitType.parking,
				},
				criteria: "Spend $20 on your total bill",
				expires: {
					hours: 12,
				},
				requiredValidations: 1,
				sortPosition: 0,
				status: OfferStatus.activated,
				universal: true,
			},
			{
				benefit: {
					key: "15-off",
					type: BenefitType.coupon,
				},
				criteria: "Come in on your birthday",
				expires: {
					days: 1,
				},
				requiredValidations: 1,
				sortPosition: 1,
				status: OfferStatus.activated,
				universal: true,
			},
			{
				benefit: {
					key: "one-free-coffee",
					type: BenefitType.coupon,
				},
				criteria: "Purchase 8 hot drinks",
				expires: {
					days: 30,
				},
				requiredValidations: 8,
				sortPosition: 2,
				status: OfferStatus.activated,
				universal: true,
			},
			{
				benefit: {
					key: "one-free-lunch",
					type: BenefitType.coupon,
				},
				criteria: "Order 6 lunch specials",
				expires: {
					months: 6,
				},
				requiredValidations: 6,
				sortPosition: 3,
				status: OfferStatus.activated,
				universal: true,
			},
		],
		profile: {
			barCodeType: BarCodeType.code39,
			country: Country.CA,
			currency: Currency.CAD,
			description: "Seattle's Best Coffee",
			flatFee: 0.45,
			homepage: "http://www.starbucks.ca",
			languages: [Language.en],
			name: "Moonbucks",
			percentFee: 3.5,
			primaryLanguage: Language.en,
			referringPartner: "movebe",
			status: MerchantStatus.approved,
			survey: "merchant-customer-satisfaction",
			telephone: "123-4567",
			type: "cafe",
		},
		searchName: "Starbucks",
	},
	{
		coupons: {},
		offers: [],
		profile: {
			country: Country.CA,
			currency: Currency.CAD,
			description: "Your driveway away from home",
			flatFee: 0,
			homepage: "http://www.standardparking.ca",
			languages: [Language.en],
			name: "Park and Go",
			percentFee: 0,
			providers: ["app-park"],
			referringPartner: "movebe",
			status: MerchantStatus.approved,
			telephone: "123-4567",
			type: "parking_lot",
		},
		searchName: "Standard Parking",
	},
	{
		coupons: {},
		offers: [],
		profile: {
			country: Country.CA,
			currency: Currency.CAD,
			description: "Ontario's premiere parking",
			flatFee: 0,
			homepage: "http://www.bestlots.ca",
			languages: [Language.en],
			name: "BestLots",
			percentFee: 0,
			providers: ["lot-buddy", "payment-kiosk"],
			referringPartner: "movebe",
			status: MerchantStatus.approved,
			telephone: "123-4567",
			type: "parking_lot",
		},
		searchName: "Green P Parking",
	},
	{
		coupons: {},
		offers: [],
		profile: {
			country: Country.CA,
			currency: Currency.CAD,
			description: "The best way to park",
			flatFee: 0,
			homepage: "http://www.parkwayparking.ca",
			languages: [Language.en],
			name: "Perfect Parking",
			percentFee: 0,
			providers: ["parkmate", "payment-kiosk"],
			referringPartner: "movebe",
			status: MerchantStatus.approved,
			telephone: "123-4567",
			type: "parking_lot",
		},
		searchName: "Parkway Parking",
	},
	{
		coupons: {},
		offers: [],
		profile: {
			country: Country.CA,
			currency: Currency.CAD,
			description: "Park with Style.",
			flatFee: 0,
			homepage: "http://www.unitpark.ca",
			languages: [Language.en],
			name: "Jojo's Parking",
			percentFee: 0,
			providers: ["paygo"],
			referringPartner: "movebe",
			status: MerchantStatus.approved,
			telephone: "123-4567",
			type: "parking_lot",
		},
		searchName: "Unit Park",
	},
	{
		coupons: {},
		offers: [],
		profile: {
			country: Country.CA,
			currency: Currency.CAD,
			description: "We've got your spot!",
			flatFee: 0,
			homepage: "http://www.parkingspot.ca",
			languages: [Language.en],
			name: "Park Boss",
			percentFee: 0,
			providers: ["payment-kiosk"],
			referringPartner: "movebe",
			status: MerchantStatus.approved,
			telephone: "123-4567",
			type: "parking_lot",
		},
		searchName: "Parking Spot",
	},
];
