import {Component, OnInit} from "@angular/core";
import {Nav, NavParams} from "@ionic/angular";
import {Store} from "@ngrx/store";
import {FormBuilder, FormGroup} from "ngx-strongly-typed-forms";
import {Observable} from "rxjs/Observable";
import * as fromMerchant from "../+state/index";
import {
	Employee,
	MerchantRole,
	MerchantUserStatus,
} from "../../../../../lib/model/employment-relationship.model";
import {MerchantLocation} from "../../../../../lib/model/merchant-location.model";
import {Merchant} from "../../../../../lib/model/merchant.model";
import {UserProfile} from "../../../../../lib/model/user/user-profile.model";
import {MovebeState} from "../../../app/movebe-state.model";
import {BusyService} from "../../../core/busy/busy.service";
import {Logger} from "../../../core/logger/logger.service";
import {MerchantsService} from "../../../core/merchants/merchants.service";
import {UsersService} from "../../../core/user/users.service";
import {filterNulls} from "../../../lib/rxjs-operators/filter-nulls";
import {UserForm} from "./user-form.model";

@Component({
	selector: "page-mm-user-modal",
	templateUrl: "./user.modal.html",
})
export class UserModal implements OnInit {
	MerchantRole = MerchantRole;
	MerchantUserStatus = MerchantUserStatus;
	readonly locations$: Observable<MerchantLocation[]>;
	readonly currentMerchant$: Observable<Merchant | undefined>;
	merchantRoles: string[];
	employee: Employee;
	user$: Observable<UserProfile>;
	userForm: FormGroup<UserForm>;

	constructor(
		private busyService: BusyService,
		private formBuilder: FormBuilder,
		private logger: Logger,
		private merchantsService: MerchantsService,
		private nav: Nav,
		private navParams: NavParams,
		private usersService: UsersService,
		private store: Store<MovebeState>
	) {
		this.currentMerchant$ = this.store.select(fromMerchant.getCurrentMerchant);
		this.locations$ = this.store.select(fromMerchant.getMerchantLocations);

		this.userForm = formBuilder.group<UserForm>({
			isActive: [false],
			role: [MerchantRole.agent],
		});
	}

	ngOnInit() {
		this.employee = this.navParams.get("employee") as Employee;
		this.user$ = this.usersService
			.getUser(this.employee.user.key)
			.pipe(filterNulls());
		this.merchantRoles = Object.keys(MerchantRole);

		this.userForm.patchValue({
			isActive:
				this.employee.employmentRelationship.status ===
				MerchantUserStatus.active,
			role: this.employee.employmentRelationship.role,
		});
	}

	cancel() {
		this.nav.pop();
	}

	saveUser(): void {
		const formValue = this.userForm.getRawValue();
		const savingUserPromise = this.currentMerchant$
			.pipe(filterNulls())
			.first()
			.toPromise()
			.then(merchant =>
				this.merchantsService.setMerchantUserEmployment(
					merchant,
					this.employee.user,
					{
						...this.employee.employmentRelationship,
						role: formValue.role,
						status: formValue.isActive
							? MerchantUserStatus.active
							: MerchantUserStatus.inactive,
					}
				)
			)
			.then(() => this.nav.pop());
		this.busyService.setBusy(savingUserPromise);
	}
}
