import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
//TODO reimplement native maps
// import {GoogleMaps} from "@ionic-native/google-maps";
import {Platform} from "@ionic/angular";
import {Observable} from "rxjs/Observable";
import {LatLngLiteral} from "../../core/mapping/lat-lng-literal";
import {MapMarker} from "./map-marker.class";

@Component({
	selector: "movebe-map",
	templateUrl: "map.component.html",
})
export class MapComponent implements OnInit {
	isCordova: boolean;

	@Input()
	highlightedItem$: Observable<{itemNumber: number; itemType: string}>;

	@Input()
	mapCenter: LatLngLiteral;

	@Input()
	offerMarkers$: Observable<MapMarker[]>;

	@Input()
	parkingMarkers$: Observable<MapMarker[]>;

	@Output()
	readonly markerClicked = new EventEmitter<{
		itemNumber: number;
		itemType: string;
	}>();

	@Output()
	readonly boundsChanged = new EventEmitter<google.maps.LatLngBounds>();

	constructor(private platform: Platform) {}

	ngOnInit() {
		this.isCordova = this.platform.is("cordova");
	}
}
