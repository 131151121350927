import {Component, EventEmitter, OnDestroy, Output} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ModalController, NavParams} from "@ionic/angular";
import {Store} from "@ngrx/store";
import {FirebaseError} from "firebase/app";
import {Subject} from "rxjs";
import {MovebeState} from "../../../app/movebe-state.model";
import {CurrentUserService} from "../../../core/user/current-user.service";
import {FirebaseAuthErrorCode} from "../../../core/user/firebase-auth-error-code.model";
import {inputsMatchValidator} from "../../../shared/form-validation/custom-validators";

@Component({
	selector: "page-mb-add-email-modal",
	templateUrl: "./add-email.modal.html",
})
export class AddEmailModal implements OnDestroy {
	justSignedIn: boolean;
	joiningMerchant: boolean;
	firebaseAuthErrorCode = FirebaseAuthErrorCode;
	@Output()
	emailAdded = new EventEmitter<boolean>();

	errorCode: FirebaseAuthErrorCode;
	private done$: Subject<void> = new Subject<void>();

	readonly minPasswordLength = 6;
	readonly addEmailForm: FormGroup;

	get email() {
		return this.addEmailForm.get("email")!;
	}

	get password() {
		return this.addEmailForm.get("password")!;
	}

	get confirmPassword() {
		return this.addEmailForm.get("confirmPassword")!;
	}

	get displayName() {
		return this.addEmailForm.get("displayName")!;
	}

	constructor(
		private modalController: ModalController,
		private navParams: NavParams,
		private currentUserService: CurrentUserService,
		private store: Store<MovebeState>,
		private formBuilder: FormBuilder
	) {
		this.justSignedIn = !!navParams.get("justSignedIn");
		this.joiningMerchant = !!navParams.get("joiningMerchant");

		this.addEmailForm = formBuilder.group(
			{
				confirmPassword: ["", Validators.required],
				displayName: ["", Validators.required],
				email: ["", [Validators.required, Validators.email]],
				password: [
					"",
					[Validators.required, Validators.minLength(this.minPasswordLength)],
				],
			},
			{
				validator: inputsMatchValidator("password", "confirmPassword"),
			}
		);
	}

	dismiss(emailAdded: boolean) {
		return this.modalController.dismiss(emailAdded);
	}

	signUpWithEmail() {
		this.currentUserService
			.linkPasswordProvider({
				displayName: this.displayName.value as string,
				email: (this.email.value as string).trim(),
				password: this.password.value as string,
			})
			.then(() => this.dismiss(true))
			.catch((error: FirebaseError) => {
				this.errorCode = error.code as FirebaseAuthErrorCode;
			});
	}

	ngOnDestroy() {
		this.done$.next();
		this.done$.complete();
	}
}
