import {I18nLookupTable} from "../i18n/i18n-lookup-table.model";

export const BenefitTypes: I18nLookupTable = {
	bikeShare: {en: "Bike Share"},
	coupon: {en: "Coupon"},
	ferry: {en: "Ferry"},
	fuel: {en: "Fuel"},
	parking: {en: "Parking", fr: "parking paye"},
	points: {en: "Points"},
	rideHail: {en: "Ride Hail"},
	toll: {en: "Toll"},
	transit: {en: "Transit"},
};
