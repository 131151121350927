import {Component, Input} from "@angular/core";
import {BenefitType} from "../../../../lib/model/benefit.model";
import {Merchant} from "../../../../lib/model/merchant.model";
import {ProviderType} from "../../../../lib/model/provider.model";
import {
	IssuedReward,
	RedeemedReward,
	RewardStatus,
} from "../../../../lib/model/reward.model";

@Component({
	selector: "movebe-reward-card",
	styleUrls: ["./reward-card.component.scss"],
	templateUrl: "./reward-card.component.html",
})
export class RewardCardComponent {
	BenefitType = BenefitType;
	ProviderType = ProviderType;
	RewardStatus = RewardStatus;

	@Input()
	reward: IssuedReward | RedeemedReward;

	@Input()
	merchant: Merchant;

	@Input()
	showBarCode = false;
}
