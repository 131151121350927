import {FirebaseEntity} from "../firebase-entity.model";
import {Omit} from "../omit.type";

export enum MessageType {
	request = "request",
	response = "response",
}

export interface ChatMessage extends FirebaseEntity {
	type: MessageType;
	from: string;
	message: string;
	timestamp: Date;
}

/**
 * A mapped type derived from ChatMessage, meant to be saved to Firestore.
 * Use a firebase ServerTimestamp instead of a Date type.
 */
export interface AddedChatMessage extends Omit<ChatMessage, "timestamp"> {
	timestamp: any;
}
